import React, { Component, useState, useEffect } from 'react';
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from 'recoil';

import loadable from "@loadable/component";

import {
  BrowserRouter,
  Routes,
  createBrowserRouter,
  RouterProvider,
  Route,
  Navigate,
  useNavigate,
  useLocation,
  Link
} from 'react-router-dom';
import { withTranslation, Translation, } from 'react-i18next';

import { isLogin, profileExists, isReservedPath } from './utils';
import i18n from "./i18n";

import LoadingPage from "./components/pages/home/LoadingPage";

import { isLoggedin, userState, authState } from "./store/stateData";const algoliasearch = require('algoliasearch');
var client = algoliasearch('0MHBU0LWTD', '529e3e0d75aa7888718f784a83d4aad4');


const viewportWidth = window.innerWidth;
const viewportHeight = window.innerHeight;

const HeaderConnectedPC = loadable(() => import("./components/header/HeaderConnectedPC"));


const HomePage = loadable(() => import("./components/pages/home/HomePage"), {
   fallback: <LoadingPage />
});

const HomeFeedPage = loadable(() => import("./components/pages/home/HomeFeedPage"), {
       fallback: <LoadingPage />
});

const VerifyCDPPage = loadable(() => import("./components/pages/rightpane/cdpresse/VerifyCDPPage"), {
       fallback: <LoadingPage />
});

const PreviewPage = loadable(() => import("./components/pages/rightpane/cdpresse/PreviewPage"), {
       fallback: <LoadingPage />
});

const EditPreviewPage = loadable(() => import("./components/pages/rightpane/cdpresse/EditPreviewPage"), {
       fallback: <LoadingPage />
});

const NoMatch = loadable(() => import("./components/pages/home/NoMatch"), {
   fallback: <LoadingPage />
});


const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <HomePage/>
    ),
  },
  {
    path: "about",
    element: <div>About</div>,
  },
]);

/*
<RouterProvider router={router} />
*/

const ProtectedRoute = ({ isSignedin, children }) => {

  if (!isSignedin) {
    return <Navigate to="/" replace />;
  }

  return children;
};

const PublicRoute = ({ isSignedin, children }) => {

  if (isSignedin) {
    return <Navigate to="/home" replace />;
  }

  return children;
};

const TOKEN_KEY = 'jwtToken';

var ws;



const App = () => {
      let isTypingTimeout;
      const currentYear = new Date().getFullYear();
      const [isSignedin, setIsSignedin] = useRecoilState(isLoggedin);
      const [sinUser, setSinUser] = useRecoilState(userState);
      const [authStatus, setAuthStatus] = useState('init');

      // JEUNE MAIS
      const [waitingSignature, setWaitingSignature] = useState(0);
      const [signedAndActive, setSignedAndActive] = useState(0);
      const [pendingContracts, setPendingContracts] = useState(0);
      const [signedAndExpired, setSignedAndExpired] = useState(0);
      const [openedTotalSrJeunemais, setOpenedTotalSrJeunemais] = useState(0);
      const [closedTotalSrJeunemais, setClosedTotalSrJeunemais] = useState(0);
      const [debyooTotalSrJeunemais, setDebyooTotalSrJeunemais] = useState(0);
      const [userTotalSrJeunemais, setUserTotalSrJeunemais] = useState(0);

      // CDPRESSE
      const [openedTotalScCdpresse, setOpenedTotalScCdpresse] = useState(0);
      const [resolvedTotalScCdpresse, setResolvedTotalScCdpresse] = useState(0);
      const [debyooTotalScCdpresse, setDebyooTotalScCdpresse] = useState(0);
      const [userTotalScCdpresse, setUserTotalScCdpresse] = useState(0);

      const [openedTotalSrCdpresse, setOpenedTotalSrCdpresse] = useState(0);
      const [closedTotalSrCdpresse, setClosedTotalSrCdpresse] = useState(0);
      const [debyooTotalSrCdpresse, setDebyooTotalSrCdpresse] = useState(0);
      const [userTotalSrCdpresse, setUserTotalSrCdpresse] = useState(0);

      const [activeTotalCdpresses, setActiveTotalCdpresses] = useState(0);
      const [inactiveTotalCdpresses, setInactiveTotalCdpresses] = useState(0);
      const [pendingTotalCdpresses, setPendingTotalCdpresses] = useState(0);
      const [rejectedTotalCdpresses, setRejectedTotalCdpresses] = useState(0);

      const [activeTotalMediaCdpresses, setActiveTotalMediaCdpresses] = useState(0);
      const [inactiveTotalMediaCdpresses, setInactiveTotalMediaCdpresses] = useState(0);
      const [pendingTotalMediaCdpresses, setPendingTotalMediaCdpresses] = useState(0);
      const [rejectedTotalMediaCdpresses, setRejectedTotalMediaCdpresses] = useState(0);


      // DCAREER
      const [newDCEmail, setNewDCEmail] = useState(null);
      const [newDCSalesrequest, setNewDCSalesrequest] = useState(null);

      const [openedTotalDC, setOpenedTotalDC] = useState(0);
      const [closedTotalDC, setClosedTotalDC] = useState(0);
      const [debyooTotalDC, setDebyooTotalDC] = useState(0);
      const [userTotalDC, setUserTotalDC] = useState(0);

      const [activeTotalJobs, setActiveTotalJobs] = useState(0);
      const [inactiveTotalJobs, setInactiveTotalJobs] = useState(0);
      const [pendingTotalJobs, setPendingTotalJobs] = useState(0);


      // YIJ
      const [newMessage, setNewMessage] = useState(null);
      const [newRoom, setNewRoom] = useState(null);


      const [openedTotal, setOpenedTotal] = useState(0);
      const [resolvedTotal, setResolvedTotal] = useState(0);
      const [debyooTotal, setDebyooTotal] = useState(0);
      const [userTotal, setUserTotal] = useState(0);
      const [isTyping, setIsTyping] = useState(null);

      const [reloadPage, setReloadPage] = useState(null);
      const [totalRequests, setTotalRequests] = useState(0);
      const [totalDreamers, setTotalDreamers] = useState(0);
      const [totalInitiators, setTotalInitiators] = useState(0);
      const [totalElders, setTotalElders] = useState(0);
      const [debyooEmployees, setDebyooEmployees] = useState(0);
      const [visitors, setVisitors] = useState(0);
      const [members, setMembers] = useState(0);
      const [nonmembers, setNonmembers] = useState(0);
      const [notsignedins, setNotsignedins] = useState(0);
      const [mdreamers, setMdreamers] = useState(0);
      const [minitiators, setMinitiators] = useState(0);
      const [melders, setMelders] = useState(0);
      const [reqdreamers, setReqdreamers] = useState(0);
      const [reqinitiators, setReqinitiators] = useState(0);
      const [reqelders, setReqelders] = useState(0);
      const [mcountries, setMcountries] = useState([]);
      const [rippleringsData, setRippleringsData] = useState([]);
      const [rippleringsLat, setRippleringsLat] = useState(0);
      const [rippleringsLng, setRippleringsLng] = useState(0);
      const [maplabelsData, setMaplabelsData] = useState([]);

      const [receivedACK, setReceivedACK] = useState(null);
      const [seenACK, setSeenACK] = useState(null);

      const [contractsResult, setContractsResult] = useState([]);


      useEffect(() => {

        fetch('https://api.ipstack.com/check?access_key=9cd4ffbf45a257de9e644d4d875a9465')
                .then((response) => response.json())
                .then((responseData) => {
                     //alert("App. Store: " + JSON.stringify(responseData.country_code));

                     global.country = responseData.country_code;

                  })
                 .catch(function(error) {

                 });

        async function signalChatSendersThatIReceivedMessages() {

            try {

                let url = `https://bjhg2qil37.execute-api.us-east-1.amazonaws.com/prod/ihavereceivedmessages?uid=Debyoo&t=all`;

                let response = await fetch(url, {method: 'POST'});

                let res = await response.json();

              //  console.log('>> App index ihavereceivedmessages res >> ' + JSON.stringify(res));

                return res;
            }
            catch(error)
            {
               return error;
            }
          }
          signalChatSendersThatIReceivedMessages();



      }, []);



      useEffect(() => {



        if (isSignedin)
        {

            //  var ip = generateIPForREST();




             let user= JSON.parse(sinUser);

             authConnection(user);


             loadJeunemaisDashboardData();



              var jwtToken = user.jwt_token; //localStorage.getItem("jwtToken") || '';

              loadYIJTotalRequests(jwtToken);


              //  this.props.setLanguage(responseData.country_code);
              fetch('https://api0.debyoo.com/check_dbo_auth_api', {
                                        method: 'POST',
                                        headers: {
                                          'Accept': 'application/json',
                                           'Authorization': jwtToken,
                                          'Content-Type': 'application/json'
                                        },
                                         credentials: 'include',
                                        body: JSON.stringify({
                                          auth_check: 'jwt',
                                          access_token: user.access_token,
                                          typef: 'global'
                                        })
                                })
                  .then((response) => response.json())
                  .then((responseData) => {


                      // console.log('>>>> Yooooo :: ' + JSON.stringify(responseData))

                         if (responseData.status == "success")
                         {

                              // console.log('>> Gooooos');
                               setAuthStatus(responseData.dbo_auth_grants);

                              // console.log('>>> authStatus :::: ' + authStatus);

                         }
                         else
                         {


                             setAuthStatus('0');
                         }


                     })
                   .catch(function(error) {

                   }.bind(this));

          }


   }, []);

   const loadJeunemaisDashboardData = () => {
       client.clearCache();

       setTimeout(() => {
           var index = client.initIndex('Contract');

           index
           .search('*',

           {
            // attributesToRetrieve: [],
            //filters: `cdpresse_account_auth_grants:cdpressemedia OR cdpresse_account_auth_grants:verifyingcdp OR cdpresse_account_auth_grants:rejectedcdp OR cdpresse_account_auth_grants:inactivecdp`
           })
           .then(({ hits, nbPages, page, nbHits }) =>
           {

                if (hits.length > 0)
                {
                  const _waitingSignature = hits.filter(obj =>
                    obj.status === 'waiting'
                  ).length;
                  const _signedAndActive = hits.filter(obj =>
                    obj.status === 'signed'
                  ).length;
                  const _pendingContracts = hits.filter(obj =>
                    obj.status === 'pending'
                  ).length;
                  const _signedAndExpired = hits.filter(obj =>
                    obj.status === 'expired'
                  ).length;

                  setWaitingSignature(_waitingSignature);
                  setSignedAndActive(_signedAndActive);
                  setPendingContracts(_pendingContracts);
                  setSignedAndExpired(_signedAndExpired);
                }
           });




           var index2 = client.initIndex('Salesrequest');

           index2
           .search('*',

           {
            // attributesToRetrieve: [],
            //filters: `cdpresse_account_auth_grants:cdpressemedia OR cdpresse_account_auth_grants:verifyingcdp OR cdpresse_account_auth_grants:rejectedcdp OR cdpresse_account_auth_grants:inactivecdp`
           })
           .then(({ hits, nbPages, page, nbHits }) =>
           {

                if (hits.length > 0)
                {
                  const _closed = hits.filter(obj =>
                    obj.status === 'closed'
                  ).length;
                  const _debyoo = hits.filter(obj =>
                    obj.status === 'debyoo'
                  ).length;
                  const _pending = hits.filter(obj =>
                    obj.status === 'pending'
                  ).length;
                  const _user = hits.filter(obj =>
                    obj.status === 'user'
                  ).length;

    
                  setOpenedTotalSrJeunemais(_pending);
                  setClosedTotalSrJeunemais(_closed);
                  setDebyooTotalSrJeunemais(_debyoo);
                  setUserTotalSrJeunemais(_user);
                }
           });

       }, 200);

   }

   const loadCdPresseMedia = () => {

         client.clearCache();

         setTimeout(() => {
             var index = client.initIndex('User');

             index
             .search('*',

             {
               attributesToRetrieve: [
                 "objectID","account_category","name","screenname","picture","account_is_verified","verified_display","cdpresse_account_auth_grants","cdpresse_account_categories","cdpresse_account_type",
                 "cdpresse_legal_represant_name", "cdpresse_verif_urls", "created_at", "cdpresse_verif_doc_url", "cdpresse_verif_doc_id", "cdpresse_verif_doc_type", "cdpresse_verif_requested_at", "cdpresse_reason_array", "cdpresse_reason"
               ],
              filters: `cdpresse_account_auth_grants:cdpressemedia OR cdpresse_account_auth_grants:verifyingcdp OR cdpresse_account_auth_grants:rejectedcdp OR cdpresse_account_auth_grants:inactivecdp`
             })
             .then(({ hits, nbPages, page, nbHits }) =>
             {
                  if (hits.length > 0)
                  {
                    const countActive = hits.filter(obj =>
                      obj.cdpresse_account_auth_grants.includes('cdpressemedia')
                    ).length;
                    const countInactive = hits.filter(obj =>
                      obj.cdpresse_account_auth_grants.includes('inactivecdp')
                    ).length;
                    const countPending = hits.filter(obj =>
                      obj.cdpresse_account_auth_grants.includes('verifyingcdp')
                    ).length;
                    const countRejected = hits.filter(obj =>
                      obj.cdpresse_account_auth_grants.includes('rejectedcdp')
                    ).length;

                    setActiveTotalMediaCdpresses(countActive);
                    setInactiveTotalMediaCdpresses(countInactive);
                    setPendingTotalMediaCdpresses(countPending);
                    setRejectedTotalMediaCdpresses(countRejected);
                  }
             });

         }, 200);

   }

   const reconnectWS = () => {
      // console.log('>>> App +++++ reconnectWS <<<<');

       setTimeout(() => {
      //  console.log('>>> HomeScreen +++++ reconnectWS <<<<');

        let user= JSON.parse(sinUser);

        authConnection(user);

    }, 5000);
   }


   const authConnection = (user) => {

       //console.log('>>> YOOOO CONNECTED');

          ws = new WebSocket('wss://3x75xlu5a6.execute-api.us-east-1.amazonaws.com/production');

          ws.onopen = (e) => {
           //    console.log('>>> YOOOO CONNECTED 2222');
            //console.log('>>> WebSocket authConnection connected :: ' + JSON.stringify(e));





            //console.log('>>> this.props.session.user >>> ' + JSON.stringify(this.props.session.user));
       //     {"action": "sendRequest", "id":"5", "type":"membership", "yijmcateg":"initiator","yijmcountry":"FR", "uid": "531d719cea31ac6ff5000003", "name": "Yves Muntu", "app": "YIJ"}

         //   {"action": "setName", "auth":"1", "yijm":"1", "yijmcateg":"initiator","yijmcountry":"TG", "name": "Suya Sudeli", "uid": "1", "city": "Brussels", "country": "BE", "continent": "EU", "app": "YIJ", "device": "mobile", "platform":"ios"}

         //   {"action": "setName", "auth":"1", "yijm":"1", "yijmcateg":"elder","yijmcountry":"BE", "name": "Jean Michel", "uid": "2", "city": "Brussels", "country": "BE", "continent": "EU", "app": "YIJ", "device": "mobile", "platform":"ios"}

           //console.log('>>> Send SetName');
            let hash = {
               action: "setName",
               auth: "-1",
               yijm: "-1",
               yijmcateg: "-1",
               yijmcountry: "-1",
               name: user.name,
               uid: user.uid,
               city: "",
               country: "",
               continent: "",
               app: "YIJ",
               device: "pc",
               deviceId: "desktop",
               platform: "debyoobo"
             };
           // connection opened
            ws.send(JSON.stringify(hash)); // send a message

         };

         ws.onclose = (e) => {
            ws = null;

            reconnectWS();
           //  console.log('>>> WebSocket disconnected :: '  + JSON.stringify(e));
             // connection closed
            //console.log(e.code, e.reason);
            //ws = new WebSocket('wss://3x75xlu5a6.execute-api.us-east-1.amazonaws.com/production');
         };

         ws.onmessage  = (e) => {
            //console.log('>>> WebSocket sent message ::: '  + e + ' >>> typeof : ' + typeof(e));
            let obj, data, res;
            if (typeof(e) === "string")
            {
              // console.log('>>> CASE 1');
                obj = JSON.parse(e);

                if (typeof(obj.data) === "string")
                {
                   data = JSON.parse(obj.data);

                   res = JSON.stringify(data);
                }
                else
                {
                  data = obj.data;

                  res = JSON.stringify(data);

                }
            }
            else
            {
              //console.log('>>> CASE 2');

              obj = e;

              if (typeof(obj.data) === "string")
              {
                 data = JSON.parse(obj.data);

                 res = JSON.stringify(data);
              }
              else
              {
                data = obj.data;

                res = JSON.stringify(data);

              }

            }

               //console.log('>>> WebSocket sent message - OBJECT DATA ::: '  + obj.data + ' >>> typeof : ' + typeof(obj.data));
               // console.log('>>> WebSocket sent message - DATA ::: '  + data + ' >>> typeof : ' + typeof(data));
            //  console.log('>>> PC WebSocket sent message - res ::: '  + res + ' >>> typeof : ' + typeof(res));

              let type;
              if (typeof(res) === "string")
              {
                 res = JSON.parse(res);
                 type = res.type;
                 //console.log('>>> 1. type >>> ' + res.type);
              }
              else
              {
                type = res.type;
                //console.log('>>> 2. type >>> ' + res.type);
              }

            //  console.log('>> type :: ' + type);

          //  console.log('>>> +++++ CHCK >>> :: ' + JSON.stringify(res));

              if (type === "typing")
              {
                 //console.log('>>> +++++ Typing :: ' + JSON.stringify(res));
                 setIsTyping(res);
              }
              else if (type === "widgets")
              {
                   console.log(`>>>> Widgets message to Debyoo team :: ${JSON.stringify(res)}`);

                  if (res.dataSCCdPresse !== null)
                  {
                      let dataSCCdPresse = res.dataSCCdPresse;

                      setOpenedTotalScCdpresse(dataSCCdPresse['openedTotal']);
                      setResolvedTotalScCdpresse(dataSCCdPresse['resolvedTotal']);
                      setDebyooTotalScCdpresse(dataSCCdPresse['debyooTotal']);
                      setUserTotalScCdpresse(dataSCCdPresse['userTotal']);

                      console.log('>>> Support Cases >>> ' + JSON.stringify(dataSCCdPresse));

                  }
              }
              else if (type === "debyoo")
              {

                   console.log(`>>>> Alert message to Debyoo team :: ${JSON.stringify(res)}`);
                  //  console.log('>>>> ++++ Feature ::: ' + res.feature);
                    if (res.feature === "salesrequest")
                    {
                        //console.log('>>> YOOOO ICI 1 >>> ' + JSON.stringify(res));

                        if (res.dataDC !== null)
                        {


                            let dataDC = res.dataDC;

                            setOpenedTotalDC(dataDC['openedTotal']);
                            setClosedTotalDC(dataDC['closedTotal']);
                            setDebyooTotalDC(dataDC['debyooTotal']);
                            setUserTotalDC(dataDC['userTotal']);


                       }

                       if (res.object === 'request')
                       {
                           let objectData = res.objectData;
                           objectData.operation = res.operation;

                           //console.log('>>> App operation - room :: ' + res.operation);

                           setNewDCSalesrequest(objectData);

                           setTimeout(() => {
                             setNewDCSalesrequest(null);
                           },500);
                       }
                    }
                    else if (res.feature === "jobs")
                    {
                          if (res.dataDCJobs !== null)
                          {
                              let dataDCJobs = res.dataDCJobs;

                              setActiveTotalJobs(dataDCJobs['activeTotalJobs']);
                              setInactiveTotalJobs(dataDCJobs['inactiveTotalJobs']);
                              setPendingTotalJobs(dataDCJobs['pendingTotalJobs']);

                          }
                    }
                    else if (res.feature === "support")
                    {
                      //  alert('>>> +++++ Support Cases ICI DATA :: ' + JSON.stringify(res));

                       // SUPPORT CASES

                       if (res.dataSCCdPresse !== null && res.dataSCCdPresse !== undefined)
                       {
                           let dataSCCdPresse = res.dataSCCdPresse;

                           setOpenedTotalScCdpresse(dataSCCdPresse['openedTotal']);
                           setResolvedTotalScCdpresse(dataSCCdPresse['resolvedTotal']);
                           setDebyooTotalScCdpresse(dataSCCdPresse['debyooTotal']);
                           setUserTotalScCdpresse(dataSCCdPresse['userTotal']);

                           console.log('>>> Support Cases >>> ' + JSON.stringify(dataSCCdPresse));

                       }

                      /*  if (res.data !== null)
                        {
                            let data = res.data;

                            setOpenedTotal(data['openedTotal']);
                            setResolvedTotal(data['resolvedTotal']);
                            setDebyooTotal(data['debyooTotal']);
                            setUserTotal(data['userTotal']);

                       }*/


                       // SALES REQUEST



                       if (1===2 && res.dataSrJeunemais !== null && res.dataSrJeunemais !== undefined)
                       {

                           let dataSrJeunemais = res.dataSrJeunemais;

                           setOpenedTotalSrJeunemais(dataSrJeunemais['openedTotal']);
                           setClosedTotalSrJeunemais(dataSrJeunemais['closedTotal']);
                           setDebyooTotalSrJeunemais(dataSrJeunemais['debyooTotal']);
                           setUserTotalSrJeunemais(dataSrJeunemais['userTotal']);

                           console.log('>>> Salesrequest Join movement Jeunemais >>> ' + JSON.stringify(dataSrJeunemais));

                      }

                      if (res.dataCdPresse !== null && res.dataCdPresse !== undefined)
                      {
                          let dataCdPresse = res.dataCdPresse;

                          setOpenedTotalSrCdpresse(dataCdPresse['openedTotal']);
                          setClosedTotalSrCdpresse(dataCdPresse['closedTotal']);
                          setDebyooTotalSrCdpresse(dataCdPresse['debyooTotal']);
                          setUserTotalSrCdpresse(dataCdPresse['userTotal']);

                          console.log('>>> Salesrequest CdPresse >>> ' + JSON.stringify(dataCdPresse));

                     }

                      /* if (res.dataDC !== null)
                       {
                           let dataDC = res.dataDC;

                           setOpenedTotalDC(dataDC['openedTotal']);
                           setClosedTotalDC(dataDC['closedTotal']);
                           setDebyooTotalDC(dataDC['debyooTotal']);
                           setUserTotalDC(dataDC['userTotal']);

                          // console.log('>>> Salesrequest init YOOOO ICI 2 >>> ' + JSON.stringify(dataDC));

                      }*/


                      // MODEL PER APP
                      if (res.dataDCdpresses !== null && res.dataDCdpresses !== undefined)
                      {
                          let dataDCdpresses = res.dataDCdpresses;

                          setActiveTotalCdpresses(dataDCdpresses['activeTotalCdpresses']);
                          setInactiveTotalCdpresses(dataDCdpresses['inactiveTotalCdpresses']);
                          setPendingTotalCdpresses(dataDCdpresses['pendingTotalCdpresses']);
                          setRejectedTotalCdpresses(dataDCdpresses['rejectedTotalCdpresses']);

                          console.log('>>> Model CdPresse >>> ' + JSON.stringify(dataDCdpresses));

                          loadCdPresseMedia();

                     }


                    /*  if (res.dataDCJobs !== null)
                      {
                          let dataDCJobs = res.dataDCJobs;

                          setActiveTotalJobs(dataDCJobs['activeTotalJobs']);
                          setInactiveTotalJobs(dataDCJobs['inactiveTotalJobs']);
                          setPendingTotalJobs(dataDCJobs['pendingTotalJobs']);

                        //  console.log('>>> Salesrequest init YOOOO ICI 2 >>> ' + JSON.stringify(dataDC));

                     }*/



                       if (res.object === 'message')
                       {
                          // console.log('>> //////// Wss received new message :: ' + JSON.stringify(res.objectData));
                           let objectData = res.objectData;
                           objectData.operation = res.operation;

                           //console.log('>>> App operation - message :: ' + res.operation);

                           setNewMessage(objectData);

                           setTimeout(() => {
                             setNewMessage(null);
                           },500);

                           if (res.operation === 'create')
                           {
                              signalChatSendersThatIReceivedMessages(objectData);
                          }
                       }
                       else if (res.object === 'room')
                       {
                          //console.log('>> ////////// Wss received new room supportcase :: ' + JSON.stringify(res.objectData));
                          let objectData = res.objectData;
                          objectData.operation = res.operation;

                          //console.log('>>> App operation - room :: ' + res.operation);

                          setNewRoom(objectData);

                          setTimeout(() => {
                            setNewRoom(null);
                          },500);

                       }

                    }
                    else if (res.feature === "supportmessage")
                    {
                         //console.log('>>> ????? ++++++++ Message ACK: seen or received... :: ' + JSON.stringify(res));

                         if (res.operation === 'received')
                         {
                             if (res.uid !== undefined && res.uid !== null)
                             {

                                  setReceivedACK(res);
                             }
                         }
                         else if (res.operation === 'seen')
                         {
                             if (res.roomId !== undefined && res.roomId !== null)
                             {
                                  setSeenACK(res);
                             }
                         }

                    }
                    else if (res.categ === "all" || res.categ == "dreamer" || res.categ === "initiator")
                    {
                     let _yijCategs = countMemberCateg(res.requests);

                     setReloadPage(res.categ);
                     setReqdreamers(_yijCategs['dreamer'] === undefined ? 0:_yijCategs['dreamer']);
                     setReqinitiators(_yijCategs['initiator'] === undefined ? 0:_yijCategs['initiator']);
                     setReqelders(_yijCategs['elder'] === undefined ? 0:_yijCategs['elder']);
                   }
                   else
                   {
                     let _yijCategs = countMemberCateg(res.requests);

                     setReqdreamers(_yijCategs['dreamer'] === undefined ? 0:_yijCategs['dreamer']);
                     setReqinitiators(_yijCategs['initiator'] === undefined ? 0:_yijCategs['initiator']);
                     setReqelders(_yijCategs['elder'] === undefined ? 0:_yijCategs['elder']);
                   }



                   //console.log(`>>> YIJ Categs :: ${JSON.stringify(_yijCategs)}`);
              }
              else if (type === "actionlogs")
              {
                  //console.log('>>> changestate : reloadPage....');


                  setReloadPage("actionlogs");
              }
              else if (type === "members")
              {
                    //console.log(`Total members :: ${res.members.length}`);

                    let users = countAuths(res.users);
                    let yijMembers = countMembers(res.users);
                    let yijCategs = countMemberCateg(res.users);
                    let countries = countMemberCountries(res.users);

                    let _countries = Object.entries(countries)
                                           .sort(([,a],[,b]) => a-b)
                                           .reverse()
                                           .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});

                     let yijCountries = Object.entries(_countries);

                     let rrdata = [
                       {
                         lat: -4.322447, //(Math.random() - 0.5) * 180,
                         lng: 15.307045, //(Math.random() - 0.5) * 360,
                         maxR: 5, //Math.random() * 20 + 3,
                         propagationSpeed: 2, //(Math.random() - 0.5) * 20 + 1,
                         repeatPeriod: 1000 //Math.random() * 2000 + 200
                       }
                     ];
                     let lat = -4.322447;
                     let lng = 15.307045;

                     let mldata = [
                       {
                         lat: -4.322447,
                         lng: 15.307045,
                         total: 17000000,
                         name: 'Kinshasa'
                       },
                       {
                         lat: 50.850340,
                         lng: 4.351710,
                         total: 1200000,
                         name: 'Brussels'
                       },
                       {
                         lat: 40.730610,
                         lng: -73.935242,
                         total: 8500000,
                         name: 'New York'
                       }
                     ];





                    setDebyooEmployees(users[-1] === undefined ? 0:users[-1]);
                    setVisitors(users[1] === undefined ? 0:users[1]);
                    setMembers(yijMembers[1] === undefined ? 0:yijMembers[1]);
                    setNonmembers(yijMembers[0] === undefined ? 0:yijMembers[0]);
                    setNotsignedins(users[0] === undefined ? 0:users[0]);
                    setMdreamers(yijCategs['dreamer'] === undefined ? 0:yijCategs['dreamer']);
                    setMinitiators(yijCategs['initiator'] === undefined ? 0:yijCategs['initiator']);
                    setMelders(yijCategs['elder'] === undefined ? 0:yijCategs['elder']);
                    setMcountries(yijCountries)

                    setRippleringsData(rrdata);
                    setRippleringsLat(lat);
                    setRippleringsLng(lng);

                 //   fetch('https://raw.githubusercontent.com/vasturiano/react-globe.gl/master/example/datasets/ne_110m_populated_places_simple.geojson').then(res => res.json())
                   //     .then(({ features }) => this.setState({maplabelsData: features}));


                    setTimeout(() => {
                      rrdata = [
                        {
                          lat: -4.322447, //(Math.random() - 0.5) * 180,
                          lng: 15.307045, //(Math.random() - 0.5) * 360,
                          maxR: 5, //Math.random() * 20 + 3,
                          propagationSpeed: 2, //(Math.random() - 0.5) * 20 + 1,
                          repeatPeriod: 1000 //Math.random() * 2000 + 200
                        },
                        {
                          lat: 50.850340, //(Math.random() - 0.5) * 180,
                          lng: 4.351710, //(Math.random() - 0.5) * 360,
                          maxR: 6, //Math.random() * 20 + 3,
                          propagationSpeed: 3, //(Math.random() - 0.5) * 20 + 1,
                          repeatPeriod: 900 //Math.random() * 2000 + 200
                        }
                      ];
                      lat = 50.850340;
                      lng = 4.351710;

                      //console.log('>> Goooo gdata Brussels....');

                      setRippleringsData(rrdata);
                      setRippleringsLat(lat);
                      setRippleringsLng(lng);

                    },10000);


                    setTimeout(() => {
                      rrdata = [
                        {
                          lat: -4.322447, //(Math.random() - 0.5) * 180,
                          lng: 15.307045, //(Math.random() - 0.5) * 360,
                          maxR: 5, //Math.random() * 20 + 3,
                          propagationSpeed: 2, //(Math.random() - 0.5) * 20 + 1,
                          repeatPeriod: 1000 //Math.random() * 2000 + 200
                        },
                        {
                          lat: 50.850340, //(Math.random() - 0.5) * 180,
                          lng: 4.351710, //(Math.random() - 0.5) * 360,
                          maxR: 6, //Math.random() * 20 + 3,
                          propagationSpeed: 3, //(Math.random() - 0.5) * 20 + 1,
                          repeatPeriod: 900 //Math.random() * 2000 + 200
                        },
                        {
                          lat: 40.730610, //(Math.random() - 0.5) * 180,
                          lng: -73.935242, //(Math.random() - 0.5) * 360,
                          maxR: 7, //Math.random() * 20 + 3,
                          propagationSpeed: 3, //(Math.random() - 0.5) * 20 + 1,
                          repeatPeriod: 800 //Math.random() * 2000 + 200
                        }
                      ];
                      lat = 40.730610;
                      lng = -73.935242;

                      //console.log('>> Goooo gdata New York....');

                      setRippleringsData(rrdata);
                      setRippleringsLat(lat);
                      setRippleringsLng(lng);


                    },15000);

                  //  console.log(`>>> Users :: ${JSON.stringify(users)}`);
                    //console.log(`>>> YIJ Members :: ${JSON.stringify(yijMembers)}`);
                   // console.log(`>>> YIJ Categs :: ${JSON.stringify(yijCategs)}`);
                    //console.log(`>>> YIJ Countries :: ${JSON.stringify(yijCountries)}`);
              }

         }

    }

    const reloadDashboardWidgetData = async () => {

          try {

              let url = `https://bjhg2qil37.execute-api.us-east-1.amazonaws.com/prod/reloaddashboarddata?app=CdPresse`;

              let response = await fetch(url, {method: 'POST'});

              let res = await response.json();

              //console.log('>> reloaddashboarddata res >> ' + JSON.stringify(res));

              return res;
          }
          catch(error)
          {
             return error;
          }
    }

    const reloadActionlogs = () => {
      setReloadPage("actionlogs");
    }

    const signalChatSendersThatIReceivedMessages = async (message) => {

        try {

            let url = `https://bjhg2qil37.execute-api.us-east-1.amazonaws.com/prod/ihavereceivedmessages?uid=Debyoo&t=room&rid=${message.roomId}`;

            let response = await fetch(url, {method: 'POST'});

            let res = await response.json();

            console.log('>> ihavereceivedmessages res >> ' + JSON.stringify(res));

            return res;
        }
        catch(error)
        {
           return error;
        }

    }


    const loadYIJTotalRequests = (jwtToken) => {
    //var ip = generateIPForREST();

    //var jwtToken = localStorage.getItem("jwtToken") || '';
    //  this.props.setLanguage(responseData.country_code);
    fetch('https://api1.debyoo.com/load_yij_total_requests_api', {
                              method: 'POST',
                              headers: {
                                'Accept': 'application/json',
                                 'Authorization': jwtToken,
                                'Content-Type': 'application/json'
                              },
                               credentials: 'include',
                              body: JSON.stringify({
                                auth_check: 'jwt',
                                typef: 'global'
                              })
                      })
        .then((response) => response.json())
        .then((responseData) => {

             //alert(JSON.stringify(responseData))

               if (responseData.status == "success")
               {

                     setTotalRequests(responseData.totalRequests);
                     setTotalDreamers(responseData.totalDreamers);
                     setTotalInitiators(responseData.totalInitiators);
                     setTotalElders(responseData.totalElders);

               }


           })
         .catch(function(error) {

         }.bind(this));
    }

    const countAuths = (arr = []) => {
     const res = {};
     for(let i = 0; i < arr.length; i++){
        const { auth } = arr[i];
        if(res.hasOwnProperty(auth)){
           res[auth]++;
        }
        else{
           res[auth] = 1;
        };
     };
     return res;
   }


   const countMembers = (arr = []) => {
    const res = {};
    for(let i = 0; i < arr.length; i++){
       const { yijm } = arr[i];
       if(res.hasOwnProperty(yijm)){
          res[yijm]++;
       }
       else{
          res[yijm] = 1;
       };
    };
    return res;
  }


  const countMemberCateg = (arr = []) => {
   const res = {};
   for(let i = 0; i < arr.length; i++){
      const { yijmcateg } = arr[i];
      if(res.hasOwnProperty(yijmcateg)){
         res[yijmcateg]++;
      }
      else{
         res[yijmcateg] = 1;
      };
   };
   return res;
 }


  const countMemberCountries = (arr = []) => {
   const res = {};
   for(let i = 0; i < arr.length; i++){
      const { yijmcountry } = arr[i];
      if(res.hasOwnProperty(yijmcountry)){
         res[yijmcountry]++;
      }
      else{
         res[yijmcountry] = 1;
      };
   };
   return res;
 }

 const handleCheckWSConnection = async () => {

   console.log('>> App ws.readyState >>> ' + ws.readyState);

   if (ws !== undefined && ws.readyState !== 1)
   {

             setTimeout(() => {
               let user= JSON.parse(sinUser);

               authConnection(user);
             }, 500);


             setTimeout(async () => {
               try {

                   let url = `https://bjhg2qil37.execute-api.us-east-1.amazonaws.com/prod/ihavereceivedmessages?uid=Debyoo&t=all`;

                   let response = await fetch(url, {method: 'POST'});

                   let res = await response.json();

                   console.log('>> from YIJSupport ihavereceivedmessages res >> ' + JSON.stringify(res));

                   return res;
               }
               catch(error)
               {
                  return error;
               }
             }, 2000);
   }
   else
   {

       try {

           let url = `https://bjhg2qil37.execute-api.us-east-1.amazonaws.com/prod/ihavereceivedmessages?uid=Debyoo&t=all`;

           let response = await fetch(url, {method: 'POST'});

           let res = await response.json();

           console.log('>> from YIJSupport ihavereceivedmessages res >> ' + JSON.stringify(res));

           return res;
       }
       catch(error)
       {
          return error;
       }
   }

  }




  const handleOnKeyUp = (hash) => {

      clearTimeout(isTypingTimeout);

      //console.log('>>>> GOOOOOO TYPE ::: ' + JSON.stringify(hash));
      hash.action = "setIsTyping";
      hash.typing = true;

      try {
        ws.send(JSON.stringify(hash));
      } catch (error) {

      }

      isTypingTimeout = setTimeout(() => {

            hash.action = "setStoppedTyping";
            hash.typing = false;

            try {
              ws.send(JSON.stringify(hash));
            } catch (error) {

            }

      }, 4000);
  }





    //console.log('>> ---- sinUser >> ' + JSON.stringify(_authStatus));

       return (
         <Translation>
           {
               t =>
              <RecoilRoot>
               <BrowserRouter>
                 {isSignedin && <HeaderConnectedPC sinUser={sinUser}/>}
                 <Routes>
                   <Route path="/" element={<PublicRoute isSignedin={isSignedin}><HomePage /></PublicRoute>} />
                   <Route path="/verifycdp/:id" element={<ProtectedRoute isSignedin={isSignedin}><VerifyCDPPage /></ProtectedRoute>} />
                   <Route path="/previewpr/:id" element={<ProtectedRoute isSignedin={isSignedin}><PreviewPage /></ProtectedRoute>} />
                   <Route path="/editpreviewpr/:id" element={<ProtectedRoute isSignedin={isSignedin}><EditPreviewPage /></ProtectedRoute>} />
                   <Route path="/home" element={
                       <ProtectedRoute isSignedin={isSignedin}>
                         <HomeFeedPage sinUser={sinUser} authStatus={authStatus}
                                       newMessage={newMessage} newRoom={newRoom} newDCSalesrequest={newDCSalesrequest} isTyping={isTyping}
                                       receivedACK={receivedACK} seenACK={seenACK}
                                       reloadPage={reloadPage} totalRequests={totalRequests} totalDreamers={totalRequests} totalInitiators={totalInitiators} totalElders={totalElders}
                                        openedTotalScCdpresse={openedTotalScCdpresse} resolvedTotalScCdpresse={resolvedTotalScCdpresse} debyooTotalScCdpresse={debyooTotalScCdpresse} userTotalScCdpresse={userTotalScCdpresse}
                                        openedTotalSrCdpresse={openedTotalSrCdpresse} closedTotalSrCdpresse={closedTotalSrCdpresse} debyooTotalSrCdpresse={debyooTotalSrCdpresse} userTotalSrCdpresse={userTotalSrCdpresse}
                                        openedTotalSrJeunemais={openedTotalSrJeunemais} closedTotalSrJeunemais={closedTotalSrJeunemais} debyooTotalSrJeunemais={debyooTotalSrJeunemais} userTotalSrJeunemais={userTotalSrJeunemais}
                                        activeTotalCdpresses={activeTotalCdpresses} inactiveTotalCdpresses={inactiveTotalCdpresses} pendingTotalCdpresses={pendingTotalCdpresses} rejectedTotalCdpresses={rejectedTotalCdpresses}
                                        activeTotalMediaCdpresses={activeTotalMediaCdpresses} inactiveTotalMediaCdpresses={inactiveTotalMediaCdpresses} pendingTotalMediaCdpresses={pendingTotalMediaCdpresses} rejectedTotalMediaCdpresses={rejectedTotalMediaCdpresses}
                                        waitingSignature={waitingSignature} signedAndActive={signedAndActive} pendingContracts={pendingContracts} signedAndExpired={signedAndExpired}
                                        openedTotalDC={openedTotalDC} closedTotalDC={closedTotalDC} debyooTotalDC={debyooTotalDC} userTotalDC={userTotalDC}
                                        activeTotalJobs={activeTotalJobs} inactiveTotalJobs={inactiveTotalJobs} pendingTotalJobs={pendingTotalJobs}
                                        openedTotal={openedTotal} resolvedTotal={resolvedTotal} debyooTotal={debyooTotal} userTotal={userTotal}
                                        debyooEmployees={debyooEmployees} visitors={visitors}
                                        members={members} nonmembers={nonmembers} notsignedins={notsignedins}
                                        mdreamers={mdreamers} minitiators={minitiators} melders={melders}
                                        reqdreamers={reqdreamers} reqinitiators={reqinitiators} reqelders={reqelders}
                                        contractsResult={contractsResult}
                                        mcountries={mcountries}
                                        rippleringsData={rippleringsData} rippleringsLat={rippleringsLat} rippleringsLng={rippleringsLng}
                                        maplabelsData={maplabelsData}
                                        handleCheckWSConnection={handleCheckWSConnection}
                                        handleOnKeyUp={handleOnKeyUp} reloadActionlogs={reloadActionlogs}
                                        reloadDashboardWidgetData={reloadDashboardWidgetData}/>
                      </ProtectedRoute>} />
                   <Route path="*" element={<NoMatch />} />
                 </Routes>
               </BrowserRouter>



             </RecoilRoot>
            }
        </Translation>
      );


}

export default App;
