import React, { Component } from 'react';
import { withTranslation, Translation, } from 'react-i18next';


import { View, ActivityIndicator} from "react-native-web";



const viewportWidth = window.innerWidth;
const viewportHeight = window.innerHeight;

export default function LoadingPage() {




    return (

	         <div>


                  <View style={{width:viewportWidth, height: viewportHeight, justifyContent: 'center', alignSelf: 'center'}}>

                    <div className="main_header">
                      <div className="main_header_inside">
                        <div className="main_header_inside_left">
                            <img src={'https://d3van47cb9u9tj.cloudfront.net/debyoo-logo-w300.png'} alt="Debyoo Logo"/>
                        </div>

                        <div className="main_header_inside_left_2" style={{display: 'none', width: '70%'}}>
                             <a href="/dplay" className="nonactiveHeaderLink">DPlay</a>
                            <a href="/about/dshop" className="nonactiveHeaderLink">DShop</a>
                            <a href="/about/dchat" className="nonactiveHeaderLink">DChat</a>
                            <a href="https://www.yinitj.com/" target="_blank" className="nonactiveHeaderLink">Youth&nbsp;Initiative&nbsp;Jeunesse</a>
                        </div>

                      </div>
                    </div>

                    <div style={{textAlign: 'center', marginBottom: '20px'}}>
                      	<img src={'https://d3van47cb9u9tj.cloudfront.net/debyoo-logo-w300.png'} alt="Debyoo Logo" style={{width: '200px'}}/>
                    </div>
                    <ActivityIndicator  animating={true} color="#0079ff" />
                  </View>





	        </div>

    )

}
