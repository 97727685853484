import React from "react";
import ReactDOM from "react-dom";
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";

i18n
.use(initReactI18next) // passes i18n down to react-i18next
.init({
  resources: {
    en: {
      translation: {
        Journalist: 'Journalist',
        Reporter: 'Reporter',
        Columnist: 'Columnist',
        Blogger: 'Blogger',
        writtenPress: 'Written press',
        onlinePress: 'Online press',
        radioPress: 'Radio press',
        tvPress: 'TV press',
        Personal_profile: "Personal profile",
        Brand: "Brand",
      	Company: "Company",
        Institution: "Institution",
      	Organization: "Organization",
      	Association: "Association",
      	Sports_team: "Sports team",
      	Church: "Church",
      	Religious_organization: "Religious organization",
      	Place_lieu: "Place",
        Event: "Event",
        Account: "Account",
        General_question: "General question",
        Hi: "High",
        High: "High",
        Low: "Low",
        Mid: "Middle",
        marked_as_resolved: "marked this case as resolved",
        opened_a_case: "opened a new support case",
        closed_this_case: "closed this support case",
        auto_closed_this_case: "This support case has been automatically closed",
        reopened_this_case: "reopened this support case",
        connected_to_serve_you: "connected to serve",
        took_care_of_this_case: "has taken care of this case",
        undertook_this_case: "released this case",
        locked_this_case: "locked this case",
        unlocked_this_case: "unlocked this case",
        Dashboard: "Dashboard",
        Feed: "Feed",
        Legal_documents: "Contracts & Legal documents",
        Contacts_and_support: "Contacts & Support",
        Analytics: "Analytics",
        Sandbox: "Sandbox",
        Admins: "Admins",
        Contact_persons: "Contact persons",
        Business_models: "Business models",
        Search_noun: "Search",
        Place_4dresume: "Place 4D-Resume",
        More_about: "More about",
        Personality: 'Personality',
      	Experience_and_skills: 'Experience and skills',
      	Social_impact: 'Social_impact',
      	The_personality: 'Personality',
      	The_experience_and_skills: 'Experience and skills',
      	The_storytelling: "Storytelling",
      	The_social_impact: 'Social impact',
      	Jobs: 'Jobs',
      	Job: 'Job',
      	Student_job: "Student job",
      	Student_jobs: "Student jobs",
      	Apply_to_job: "Apply to job",
      	Paid_intership: "Paid intership",
        Careers: "Careers",
        enter_flooz_pin_code: "A verification SMS has been sent to you. Please open it and enter your Flooz security code to confirm this transaction.",
        enter_tmoney_pin_code: "A verification SMS has been sent to you. Please open it and enter your TMoney security code to confirm this transaction.",
        Reason: "Reason",
        Insufficient_funds: "Insufficient funds",
        Please_enter_the_name: "Please enter the name",
        Pay_now: "Pay now",
        Card_holder_name: "Card holder's name",
        Pay_with: "Pay with",
        Your_moov_number: "Your Moov number",
        Your_togocom_number: "Your Togocom number",
        Your_vodacom_number: "Your Vodacom number",
        Your_orange_number: "Your Orange number",
        You_have_made_a_donation: "You have made a donation",
        Internal_error_message: "Sorry an error occured. Please check back later.",
        Payment_successful: "Payment successful",
        Donations_are_tax_deductible: "Donations are tax deductible",
        This_donations_will_not_be_tax_deductible: "This donation will not be tax deductible",
        Beneficiary: "Beneficiary",
        Donation_amount: "Donation amount",
        Anonymous: "Anonymous",
  	Other_amount: "Other amount",
  	Change_the_amount: "Change the amount",
  	Donations_are_tax_deductible: "Donations are tax deductible",
  	Fundraising: "Fundraising",
  	Enabled_m: "Enabled",
  	Disabled_m: "Disabled",
  	Enabled_f: "Enabled",
  	Disabled_f: "Disabled",
  	Intenational_non_governmental_organization: "International non-governmental organization",
  	Non_governmental_organization: "Non-governmental organization",
  	Charity: "Charity",
  	Non_profit_organization: "Nonprofit organization",
  	Type_of_entity: "Type of entity",
  	Want_to_enable_permanent_fundraising_on_your_profile: "Want to enable permanent fundraising on your profile?",
  	Apply_to_enable_fundraising: "Apply to enable fundraising",
  	Enabling_fundraising_permanently: "Enabling fundraising on your profile permanently",
  	Fundraising_permanently: "Fundraising on your profile permanently",
  	Pending_application: "Pending application",
  	Permanent_fundraising_on_your_profile_enabled: "Permanent fundraising on your profile enabled",
  	Fundraising_reports: "Fundraising reports",
  	Your_payment_was_declined: "Your payment was declined",
  	Your_aborted_the_transaction: "Your aborted the transaction",
  	Use_another_payment_method: "Use another payment method",
  	Thank_you_for_the_donation: "Thank you for the donation.",
  	We_have_sent_you_an_email_confirming_the_details_of_your_donation: "We have sent you an email confirming the details of your donation.",

        Payment_methods: "Payment methods",
  	Payment_method: "Payment method",
  	Change_payment_method: "Change payment method",
  	Select_a_new_payment_method: "Select a new payment method",
  	Choose_another_payment_method: "Choose another payment method",
  	Choose_verb: "Choose",
  	Pay_with_bancontact: "Pay with Bancontact",
  	Connecting_with_bancontact: "Connecting with Bancontact",
  	Connecting_with_paypal: "Connecting with PayPal",
  	Pay_with_a_card: "Pay with a card",
  	Pay_with_paypal: "Pay with Paypal",
  	Pay_with_flooz: "Pay with Flooz",
  	Pay_with_tmoney: "Pay with TMoney",
  	please_verify_your_mobile_payment_information: "Please verify your phone number and/or check you have sufficient funds for the purchase",
  	Select_a_payment_method: "Select a payment method",
  	Credit_debit_card: "Credit/Debit Card",
  	Enter_your_moov_number: "Enter your Moov number",
  	Enter_your_togocel_number: "Enter your Togocel number",
  	Done: "Done",
  	New_story: "New story page",
  	Library: "Library",
  	Cart: "Cart",
  	Messages: "Messages",
  	Give_credit: "Give Credit",
  	Request_credit: "Request Credit",
  	New_plug: "New Plug",
  	Share: "Share",
  	Place_order: "Place order",
  	Verify_your_moov_number: "Verify your Moov number",
  	Verify_your_togocel_number: "Verify your Togocel number",
         Your_order_has_been_received: "Your order has been received",
         Thank_you_for_your_purchase: "Thank you for your purchase!",
        Your_order_number_is: "Your order number is",
         You_will_receive_an_order_confirmation: "You will receive an order confirmation email with details of your order.",

        Your_are_about_to_donate: "Your are about to donate",
        Share_verb: "Share",
        Select_one: "Select one",
        Myself_only: "Myself only",
        Review_donation: "Review donation",
        Select_a_payment_method: "Select a payment method",

        About_me: "About me",
      	City: "City",
      	Birthdate: "Birthdate",
      	Gender: "Gender",
      	Contact: "Contact",
      	years_active: "Years active",
      	Instruments: "Instruments",
      	Measurements: "Measurements",
      	Type_of_modeling: "Type of modeling",
      	Fabric: "Fabric",
      	Category: "Category",
      	Type: "Type",
      	Position: "Position",
      	Genres: "Genres",
      	Topics: "Topics",
      	Sport: 'Sport',
      	Occupation: "Occupation/Role",
      	Field: "Field",
      	Title: "Title",
      	male: "Male",
      	female: "Female",
        Eyes: "Eyes",
      	About_shoe: "Shoe",
        Chest: "Chest (Jacket)",
        Bust: "Bust",
        Waist: "Waist",
        Hips: "Hips",
        Height: "Height",
      	Type_of_modeling: "Type of modeling",
      	Products: "Products",
      	Product: "Product",
      	Fabric: "Fabric",
      	Fabrics: "Fabrics",
        Brands: "Brands",
  	    Entity_Brands: "Entity/Brands",
        Specialities: "Specialities",
  	    Career_metier: "Occupation",
        Specialities_careers: "Specialities/Occupation",
  	    MMYY: "MM/YY",


      	Year_of_birth_not_public: "Year of birth not public",
      	Not_public: "Not public",

        Click_on_any_of_the_facets_to_discover_more: "Click on any of the facets to discover more",
        Tap_any_of_the_facets_to_discover_more: "Tap any of the facets to discover more",
        related_to_this_facet: 'related to this facet',
        We_re_rethinking_resume_and_cv: "We're rethinking the resume and the curriculum vitae (CV).",
        There_is_no_content_available: "There is no content available",
        This_xwall_has_no_content_yet: "This X-Wall (eXperience Wall) has no content yet.",
        This_xpage_has_no_content_yet: "This X Page (eXperience Page) has no content yet.",
        All_your_experience_skills_and_credits_me: "All your experiences (learning, training, work...), skills and credits related to the selected facet will appear here.",
        All_your_global_experience_skills_and_credits_me: "All your global experiences (learning, training, work...), skills and credits will appear here.",
        All_your_experience_skills_and_credits_start: "All",
        All_your_experience_skills_and_credits_end: " experiences (learning, training, work...), skills and credits related to the selected facet will appear here.",
        All_your_global_experience_skills_and_credits_end: " global experiences (learning, training, work...), skills and credits will appear here.",
        will_appear_here: "will appear here.",

        Posted: "Posted",
        Expected: "Expected",
        Facet_successfully_added: "Facet successfully added",
      	Facet_deleted: "Facet deleted",
      	with_a_story_page: "with a story page",
      	with_an_audio: "with an audio",
      	with_a_photo: "with a photo",
      	with_a_text: "with a text",
      	with_a_video: "with a video",
      	with_a_link: "with a link",
      	Plug_successfully_added: "Plug successfully added",
      	Delete_this_plug: "Delete this Plug",
      	Are_you_sure_you_want_to_delete_this_plug: "Are you sure you want to delete this plug?",
      	Search_and_then_select: "Search and then select in the search results",
      	Search_and_then_select_the_story_page: "Search and then select the story page",
      	Press_to_record_audio: "Press to record audio",
      	Stop: "Stop",
      	Type_here_to_search: "Type here to search",
      	Confirm_the_attachment: "Confirm the attachment",
      	Remove_the_attachment: "Remove the attachment",
      	Remove_attachment: "Remove attachment",
      	No_attachments: "No attachments",
      	Attachment_successfully_added: "Attachment successfully added",
      	Attach_story_post: "Attach Story Page/Post",
      	Are_you_sure_you_want_to_remove_the_attachment: "Are you sure you want to remove the attachment?",
      	Soft_skill_successfully_added: "Soft skill successfully added",
      	Hard_skill_successfully_added: "Hard skill successfully added",
      	Activity_statement_successfully_added: "Activity statement successfully added",
      	Education_formation: "Education",
      	Graduation_date: "Graduation date",
      	Graduation_expected_date: "Expected graduation date",
      	Get_direction: "Get direction",
      	Schools_around_me: "Schools around me",
      	Radius: "Radius",
      	Change_radius: "Change radius",
      	Sorry_no_results_found: "Sorry, no results were found",
      	Type_of_school: "Type of school",
      	Classic: "Classic",
      	International: "International",
      	International_fem: "International",
      	Private_fem: "Private",
      	Public_fem: "Public",
      	Search_filters: "Search filters",
      	Apply: "Apply",
      	Innovation_or_classic: "Innovation or classic?",
      	Save_with_unlisted_neighb: "Save with unlisted name",
      	Listed_in_alphabetical_order: "Listed in alphabetical order",
      	Players_in_the_music_industry: "Players in the music industry",
      	Players_in_the_agrobusiness: "Players in the agrobusiness",
      	Add_activity_statement: "Add activity statement",
      	Activity_statement: "Activity statement",
      	Activity_statements: "Activity statements",
      	Add_skill: "Add skill",
      	Soft_skill: "Soft skill",
      	Hard_skill: "Hard skill",
      	Soft_skills: "Soft skills",
      	Hard_skills: "Hard skills",
      	Add_a_skill_or_an_activity: "Add a skill or an activity",
      	Relate_it_to_one_or_more_facets: "Relate it to one or more facets",
      	Relate_it_to_one_or_more_of_your_facets: "Relate it to one or more of your facets",
      	Relate_the_selected_skill_to_one_or_more_of_your_facets: "Relate the selected skill to one or more of your facets",
      	No_facets_has_been_added_yet: "No facets have been added, yet?",
      	Will_be_related_only_to_the_selected_facet: "Will be related only to the selected facet",
      	The_selected_skill_is_related_to_the_current_facet_only: "The selected skill is related to the current facet only",
      	No_soft_skills_added_yet: "No soft skills added yet",
      	No_hard_skills_added_yet: "No hard skills added yet",
      	No_activity_statements_yet: "There are no activity statements to display currently",
      	Person: "Person",
      	Action: "Action",
      	Object: "Object",
      	Choose_an_action: "Choose an action",
      	Define_the_action: "Define the action",
      	Define_the_object: "Define the object",
      	Enter_the_target: "Enter the target",
      	Choose_the_verb: "Choose the verb + the complement",
      	Target_plus_verb: "(Target + Verb)",
      	Target: "Target",
      	Verb: "Verb",
      	Type_plus_verb: "(Verb + complement + provider name)",
      	Type_plus_verb_en: "(Verb + provider name + complement)",
      	Verb_complement: "Verb + complement",
      	Change_verb: "Change verb",
      	Type_of_activity: "Type of activity",
      	Type_of_object: "Type of object",
      	Type_of_job_or_task: "Type of job or task",
      	Choose_a_sector: "Choose a sector",
      	Choose_the_type_of_object: "Choose the type of object",
      	Choose_the_type_of_job_or_task: "Choose the type of job or task",
      	Name_of_the_object: "Name of the object",
      	Examples_provider_job_task: "Ex: Brand name, Company name, Event name...",
      	Name_of_activity: "Provider name",
      	Enter_the_type_of_activity: "Enter the type of activity",
      	Enter_the_name_of_activity: "Enter the provider name",
      	Type_of_activity_you_interacted_with: "Type of activity you interacted with",
      	Name_of_activity_you_interacted_with: "Name of activity you interacted with",
      	Topic_subject_or_title: "Topic, subject or title",
      	Select_at_least_one_skill_related_to_this_activity: "Select at least one skill related to this activity",
      	Select_at_least_one_related_skill: "Select at least one related skill",
      	if_applicable: "if applicable",
      	Degree_type_and_field: "Degree type and field of study",
      	Field_of_study: "Field of study",
      	Name_of_the_school: "Name of the educational facility",
      	Location_of_the_school: "Location of the educational facility",
      	You_havent_added_any_skills_yet: "You haven't added any skills yet.",
      	Add_skills: "Add skills",
      	on_sur: "on",
      	in_sur: "in",
      	for_pour: "for",
      	of_de: "of",
      	about_sur: "about",
      	Additional_information: "Additional information",
      	Source: "Source",
      	When_mark: "When?",
      	Add_date: "Add date",
      	Add_url: "Add URL",
      	Add_mentions_gpa: "Add mentions...",
      	Enter_additional_information_here: "Enter additional information here",
      	Examples_degree_context: "Major, minor, GPA, mentions, honors, etc.",
      	Examples_was_hired_context: "Achievements, responsibilities, tasks, etc.",
      	Source_url: "Source: URL",
      	Date: "Date",
      	When_was_the_object_acted_on: "When was the object acted on?",
      	Remove_date: "Remove date",
      	Broken_link_alert: "This is broken link. We suggest you to delete this activity statement and add a new one with a valid link.",
      	Broken_link: "Oops, sorry! This is broken link.",
      	Approve_report: "Approve report",
      	Uniform: "Uniform",
      	No_uniform: "No uniform",
      	Public_or_private_school: "Public or private school?",
      	Wearing_uniform: "Wearing uniform?",
      	Uniform_pictures: "Uniform pictures",
      	Location_lieu: "Location (city, state)",
      	Expected_date: "Expected date",
      	Date_of_issuing: "Date of issuing",
      	Type_of_course: "Type of course",
      	Type_of_training: "Type of training",
      	Type_of_internship: "Type of internship",
      	Field_area: "Name/Title/Subject/Field",
      	Name_title_of_the_work: "Name/title of the work",
      	Add_infos: "Add info",
      	Name_event: "Name/Event...",
      	Till_now: "Till now",
      	Till_now_present: "Present",
      	Jobposition: "Position",

      	Enter_the: "Enter the",
      	Type_of_degree: "Type of degree",
      	Type_of_certification: "Type of certification",
      	Type_of_licence: "Type of licence",
      	Type_of_membership: "Type of membership",
      	Type_of_award: "Type of award",
      	Type_of_honor: "Type of honor",
      	Employer_name: "Employer name",
      	Job_title_and_position: "Job title and position",
      	Album_title: "Album title",
      	Name_of_artist_group_orchestra: "Name of artist/group/orchestra",
      	Artist_name: "Artist name",
      	Venue_name: "Venue name",

      	Name_of_issuing_body: "Name of issuing body",
      	Name_of_issuing_honors_body: "Name of issuing body",
      	Name_of_awarding_body: "Name of awarding body",
      	Name_of_certifying_body: "Name of certifying body",
      	Name_of_licensing_body: "Name of licensing body",
      	Name_of_membership_body: "Name of membership body",
      	Name_of_the_high_school: "Name of the high school",
      	Name_of_the_college: "Name of the college",
      	Name_of_the_university: "Name of the university",

      	added_a_soft_skill: "added a soft skill",
      	added_a_soft_skill_to_his_x_page: "added a soft skill to his/her X Page",
      	added_a_hard_skill: "added a hard skill",
      	added_a_hard_skill_to_his_x_page: "added a hard skill to his/her X Page",
      	View_the_x_page: "View the X Page",
      	View_the_x_graph: "View eXperience Graph",
      	Type_the_skill_here: "Type the skill here",

      	New_data_nlles: "New entry",
      	Entry: "Entry",
      	Universities: "Universities",
      	Universities_colleges: "Universities/Colleges",
      	High_schools: "High schools",
      	Influencers: "Influencers",
      	Points_of_interests: "Points of interest",
      	Size_store: "Size",
      	Add_geolocation_coordinates: "Add geolocation coordinates?",
      	Photos_up_to_height: "Photos (max 8)",
      	Add_photo: "Add photo",
      	Data_successfully_saved: "Data successfully saved",
      	Entries: "Entries",
      	Geolocation: "Geolocation",
      	Photos: "Photos",
      	Notoriety: "Notoriety",
      	Plus_website_sn: "+ Website, Social networks",
      	Sector: "Sector",
      	Individual: "Individual",
      	Cooperative: "Cooperative",
      	Speciality_occupation: "Speciality/Occupation",
      	University: "University",
      	College: "College",
      	Employees: "Employees",
      	Save_silently: "Save silently",
      	Entries_from: "Entries from",
      	entries_to: "to",
      	Distribution_of_entries: "Distribution of entries",
      	View_all_facets: "View all facets",
      	Add_facets: 'Add facets',
      	No_facets_yet: "No facets added yet",
      	Create_story_page: "Create story page",
      	x_page_of: "X Page of",
      	Add_story_page: "Add story page",
      	All_facets: "All facets",
      	All_my_facets: "All my facets",
      	All_my_facets_included: "All my facets included",
      	From_all_facets_of_me: "From all facets of me",

      	Supermarket: "Supermarket",
      	Bonjour_total: "Bonjour Total",

      	Posted_by: "Posted by",
      	Topics: "Topics",
      	No_topics_added: "No topics added",
      	New_topic: "New topic",
      	All_messages: "All messages",
      	Add_to_remove_from_topic: "Add to/Remove from topics",
      	Daily_activity_report: "Daily activity report",
      	Activity_report: "Activity report",
      	My_activity_reports: "My activity reports",
      	Your_last_activity_report_has_been_approved: "Your last activity report has been approved",
      	Approved: "Approved",
      	Sent_on: "Sent",
      	To_be_reviewed: "To be reviewed",
      	Task: "Task",
      	Tasks: "Tasks",
      	Employee_name: "Employee Name",
      	Employee_identification_number: "Identification Number",
      	Department: "Department",
      	Week: "Week",
      	Completed_tasks: "Completed Tasks",
      	Ongoing_tasks: "Ongoing Tasks",
      	Tasks_for_next_week: "Tasks for Next Week",
      	Report_successfully_sent: "Report successfully sent",
      	No_tasks: "No Tasks",
      	New_task: "New Task",
      	Start_date: "Start date",
      	End_date: "End date",
      	Priority: "Priority",
      	Progress: "Progress",
      	Assignees: "Assignees",
      	Start: "Start",
      	End: "End",
      	Not_started: "Not started",
      	In_progress: "In progress",
      	In_review: "In review",
      	Completed: "Completed",
      	Cancelled: "Cancelled",
      	Quit: "Quit",
      	Close: "Close",
      	a_new_task_has_been_assigned_to_you: "A new task has been assigned to you",
        one_of_your_tasks_has_been_deleted: "One of your tasks has been deleted",
        your_tasks_status_has_been_changed: "Your task's status has been changed",
        your_tasks_start_date_has_been_changed: "Your task's start date has been changed",
        your_tasks_end_date_has_been_changed: "Your task's end date has been changed",
        your_tasks_priority_has_been_changed: "Your task's priority has been changed",
        your_tasks_assignees_has_been_changed: "Your task's assignees have been changed",
        your_tasks_subject_has_been_changed: "Your task's subject has been changed",
        your_tasks_description_has_been_changed: "Your task's description has been changed",
        your_tasks_progress_has_been_changed: "Your task's progress has been changed",

        Hello: "Hello",
        Venue: "Venue",
        View_more: "View more",
        Read_more: "Read more",
        Till_now_present: "Present",
        Upload_pdf: "Upload PDF",
        Upload_audio: "Upload Audio",
        Brand_picture: "Brand picture",
        Brand_url: "Brand URL",
        Show_name: "Show name",
        Show_url: "Show URL",
        Show_program: "Show/Program",
        Click_to_enlarge: "Click to enlarge",
        Tap_to_enlarge: "Tap to enlarge",
        play: 'play',
        preview: 'preview',
        view: 'view',
        Signin_to_comment_and_react: "Sign in to comment and react",
        Contacts_and_profiles: "Contacts & profiles",
        Contact_links: "Contact links",
        Doc_in_full_view: "Doc in full view",

        youAreWatching: "You're watching",
        paused: 'Paused',
        tryAccessingOtherQuality: 'Try changing the image quality',
        goBack: 'Go Back',
        speeds: 'Speed',
        nextEpisode: 'Next Episode',
        playlist: 'Playlist',
        playError: 'Something went wrong playing this video',
        Audios: 'Audios',
        Audio: 'Audio',
        Subtitles: 'Subtitles',
        deactivated: "Deactivated",
        fr: "French",
        en: "English",
        ew: "Ewe",
        tm: "Tem (Kotokoli)",
        ka: "Kabyè",

        Website: "Website",
        No_links_to_display: "No links to display",

        Type_a_message: "Type a message",

        Reply: "Reply",
        View_replies: "View replies",

        Yesterday: "Yesterday",
        You_vous: "You",
        Audio: "Audio",
        Voice_messsage: "Voice message",
        Voice_message: "Voice message",
        Document: "Document",
        Contact: "Contact",
        Location: "Location",
        You_changed_your_phone_number: "You changed your phone number",
        changed_their_phone_number: "changed their phone number",
        changed_their_phone_number_long_message: "changed their phone number to a new number. Tap to message or add the new number",
        Message_deleted: "Message deleted",
        This_message_was_deleted: "This message was deleted",
        To_start_messaging_contacts_who_have_activated_dchat: "To start messaging contacts who have activated dChat, tap",
        at_the_top_of_your_screen: "at the top of your screen.",
        New_chat: "New Chat",
        Select_contact: "Select contact",
        contacts_min: "contacts",
        contact_min: "contact",
        New_contact: "New contact",
        New_group: "New group",
        NewGroup: "New Group",
        Add_participants: "Add Participants",
        Participants: "Participants",
        Group_subject: "Group Subject",
        New_group_message: "Please provide a group subject and optional group icon",
        created_the_group: "created the group",
        created_this_group: "created this group",
        You_created_this_group: "You created this group",
        You_cant_send_messsages_to_this_group: "You can't send messages to this group because you're no longer a participant.",
        changed_the_group_subject: "changed the group subject",
        You_changed_the_group_subject: "You changed the group subject",
        changed_the_group_description: "changed the group description",
        You_changed_the_group_description: "You changed the group description",
        deleted_the_group_description: "deleted the group description",
        You_deleted_the_group_description: "You deleted the group description",
        Tap_here_to_see_the_description: "Tap here to see the description",
        changed_this_groups_icon: "changed this group's icon",
        changed_the_groups_icon: "changed the group's icon",
        You_changed_this_groups_icon: "You changed this group's icon",
        You_changed_the_group_icon: "You changed the group's icon",
        added_you: "added you",
        added: "added",
        You_added: "You added",
        removed: "removed",
        removed_you: "removed you",
        You_removed: "You removed",
        Youre_no_longer_an_admin: "You're no longer an admin",
        Youre_now_an_admin: "You're now an admin",
        Group_info: "Group info",
        Group_description: "Group description",
        Add_group_description: "Add group description",
        Group_description_message: "The group description is visile to participants of this group.",
        Edit_group_admins: "Edit admins",
        Edit_admins: "Edit group admins",
        Exit_group: "Exit group",
        Exit: "Exit",
        Report_group: "Report group",
        Edit_group_info: "Edit group info",
        Edit_group_info_message: "Choose who can change this group's suject, icon and description",
        Send_messages: "Send messages",
        Send_messages_message: "Choose who can send messages to this group",
        All_participants: "All participants",
        Only_admins: 'Only admins',
        to_group: "to",
        group_question: "group?",
        from_the_group: "from the",
        Infos: "Info",
        Send_a_message: "Send a message",
        You_made: "You made",
        madepp: "made",
        made_group_admin: "a group admin",
        Make_group_admin: "Make Group Admin",
        Make_group_admin_verb: "Make",
        a_group_admin: "a Group Admin?",
        dissmissed: "dismissed",
        You_dismissed: "You dismissed",
        dismissed_as_admin: "as admin",
        Dismiss: "Dismiss",
        Dismiss_as_admin: "Dismiss as Admin",
        as_admin: "as Admin?",
        Remove_from_group: "Remove from Group",
        from_group: "from Group?",
        Group_created_by: "Group created by",
        Created_on: "Created on",
        Save_enregistrer: "Save",
        You_left: "You left",
        participant_left: "left",
        Only_seuls: "Only",
        les_admins: "admins",
        can_send_messages: "can send messages",
        You_changed_this_group_settings_info: "You changed this group's settings to allow only admins to edit this group's info",
        changed_this_group_settings_info: "changed this group's settings to allow only admins to edit this group's info",
        You_changed_this_group_settings_info_all: "You changed this group's settings to allow all participants to edit this group's info",
        changed_this_group_settings_info_all: "changed this group's settings to allow all participants to edit this group's info",
        You_changed_this_group_settings_sendmessages: "You changed this group's settings to allow only admins to send messages to this group",
        changed_this_group_settings_sendmessages: "changed this group's settings to allow only admins to send messages to this group",
        You_changed_this_group_settings_sendmessages_all: "You changed this group's settings to allow all participants to send messages to this group",
        changed_this_group_settings_sendmessages_all: "changed this group's settings to allow all participants to send messages to this group",
        Already_added_to_the_group: "Already added to the group",
        Done_termine: "Done",
        singleselected: "selected",
        pluralselected: "selected",
        You_cant_send_messages_to_this_group_no_participant: "You can't send messages to this group because you're no longer a participant",
        Youre_no_longer_a_participant_in_this_group: "You're no longer a participant in this group",
        at_time: "at",
        You_have_been_removed: "You have been removed",
        You_have_been_added: "You have been added",
        You_added_a_participant: "You added a participant",
        You_removed_a_participant: "You removed a participant",

        Invite_verb: "Invite",
        Yello_iam_using_dchat: "Hello! I am using dChat.",
        Search_results: "Search results",
        Invite_via_sms_boby: "Check out Debyoo, I use Debyoo dChat to message the people I care about. You can download it for free at https://debyoo.com/dl",

        System_status: "System Status",
        Available: "Available",
        Open_in_the_debyoo_app: 'Download or Open in the Debyoo app',
        more_min: "more",
        Dark_mode: "Dark mode",
        Language: 'Language',
        "English": "English",
        "French": "French",
        "Download_debyoo": "Download Debyoo",
        "signin": "Sign in",
        "signup": "Sign up",
        "signout": "Sign out",
        Modify: "Modify",
        An_error_occurred_while_processing_your_request: "An error occurred while processing your request",
        account_settings: "Account Settings",
        Blocked_profiles: "Blocked Profiles",
        Payment_methods: "Payment methods",
        About_me: "About me",
        "Full_name": "Full name",
        Screenname: "Screenname",
        Email: "Email",
        "Password": "Password",
        "Forgot_password": "Forgot password?",
        sign_in_error: "Email or password is incorrect",
        you_will_receive_an_email_with_instructions: "You will receive an email with instructions about how to reset your password in a few minutes",
        A_code_has_been_sent: "A code has been sent to your email address. Please enter that code here.",
        A_code_has_been_sent_to_you: "A code has been sent to your phone number. Please enter that code here.",
        You_entered_an_incorrect_code: "You entered an incorrect code. Please check your code and try again",
        sn_taken: "Screenname has already been taken.",
        sn_invalid: "Screenname is too short (min 2 char)",
        Current_password: "Current password",
        New_password: "New password",
        New_password_confirmation: "New password confirmation",
        Current_password_is_invalid: "Current password is invalid",
        New_password_and_confirmation_password_do_not_match: "New password and confirmation password do not match",
        New_password_must_contain_at_least_characters: "New password must contain at least 6 characters",
        Password_cant_be_changed: "An error occured. Password couldn’t be changed.",
        sn_warning_one: "Your username must have at least a minimum of 2 characters",
        sn_warning_two: "Your username cannot be longer than 20 characters",
        sn_warning_three: "A username can only contain alphanumeric characters (letters A-Z, numbers 0-9) with the exception of underscores (_) and dots (.)",

        Continue: "Continue",
        Enter_code: "Enter code",
        Send_code_again: "Send code again",
        Create_a_new_password: "Create a new password",
        Type_a_new_password: "Type a new password",
        password_blank: "Password can't be blank.",
        password_short: "Password is too short (min. is 6 char).",
        name_blank: "Name can't be blank.",
        email_blank: "Email can't be blank.",
        email_taken: "Email has already been taken.",
        email_not_found: "Email doesn't match our records.",
        email_invalid: "Email is invalid.",
        resetpassword: "Reset password",
        reset: "Reset",
        I_agree_to_debyoos: "I agree to Debyoo's",
        terms_short: "Terms of Use",
        Privacy_policy: "Privacy  policy",
        and: "and",
        eula_short: "End-User Licence",
        Eulagreement: "End-User Licence Agreement",
        of_debyoo_fr: "",
        You_must_agree_to_the_terms: "You must agree to the Terms of Use and End-User Licence before signing up.",
        Welcome_to_Debyoo: "Welcome to Debyoo!",
        Change_profile_photo: "Change Profile Photo",
        Do_it_later: "I'll do it later",
        Continue: "Continue",
        Move_and_scale: "Move and Scale",
        Save: "Save",
        Cancel: "Cancel",
        "Home": "Home",
        "Our_mission": "Our mission",
        Our_vision: "Our vision",
        "Contact": "Contact",
        "Contact_us": "Contact Us",
        "Privacy": "Privacy",
        "Terms": "Terms of use",
        "Email": "Email",
        "General_inquiries": "General inquiries",
        "Press": "Press",
        "Legal": "Legal",
        "Support": "Support",
        "Music_industry": "Music industry",
        "For_inquiries_related_to_song_pages": "For inquiries related to Song Pages",
        "Fashion_industry": "Fashion industry",
        "Debyoo_introduces_songpages": "Debyoo introduces Song Pages",
        "Rethinking_songpage": "(Re)thinking music creation process, distribution and promotion.",
        "Redefining_the_future_of_digital_music_marketing_and_distribution": "Redefining the future of digital music marketing and distribution.",
        "No_ intermediaries_songpage": "Artist or independent Label? Create and publish Song Pages on Debyoo on your own, without intermediaries.",
        "Simple_transparent_models": "Simple and transparent revenue model for artists, labels, songwriters and publishers.",
        "congratulations_your_email_address_has_been_confirmed":  "Congratulations, your email address has been confirmed and your account is now activated",
        "New_story": "New Story",
        "Africa": "Africa",
        "Investor'": "Investor",
        Sorry_this_page_isnt_available: "Sorry, this page isn't available.",
        Page_doesnt_exist_no_more: "The page you requested was not found.",
        Sorry_this_post_isnt_available: "Sorry, this post isn't available.",
        Post_doesnt_exist_no_more: "The post you requested was not found.",

        Followers: "Followers",
        Following: "Following",
        follow: "Follow",
        unfollow: "Unfollow",
        unfollow_verb: "Unfollow",
        follow_tooltip: "Follow this user",
        unfollow_tooltip: "Unfollow this user",
        following_state: "Following",

        Posts_plugs: "Posts · Plugs",
        Posts_and_plugs: "Posts & Plugs",
        No_posts_plugs_yet: "No posts, no plugs yet.",
        No_plugs_yet: "No plugs yet.",

        When_people_plug: "When people plug (amplify)",
        content_those_plugs_will_live_here: "'s content, their plugs will appear here.",
        content_those_plugs_will_live_here_facet: "'s content related to this facet, their plugs will appear here.",

        When_people_plug_me: "When people plug (amplify) your content, their plugs will appear here.",
        content_those_plugs_will_live_here_me: "",

        When_people_plug_facet_me: "When people plug (amplify) your content related to this facet, their plugs will appear here.",

        Post_of_one: "",
        Post_of_two: "'s posts (profile posts and story page posts) will appear here.",
        Post_of_three: "When ",
        Post_of_four: " plugs (amplifies) his/her posts or other people posts, his/her plugs will also appear here.",

        Post_of_facet_one: "",
        Post_of_facet_two: "'s posts (profile posts and story page posts) will appear here.",
        Post_of_facet_three: "When ",
        Post_of_facet_four: " plugs (amplifies) his/her posts or other people posts, his/her plugs will also appear here.",

        Post_of_me_one:	"Your posts (profile posts and story page posts) will appear here.",
        Post_of_me_two: "When you plug (amplify) your posts or other people posts, your plugs will also appear here.",

        Post_of_me_facet_one:	"Your posts (profile posts and story page posts) related to this facet will appear here.",
        Post_of_me_facet_two: "When you plug (amplify) your posts or other people posts and related that plugs to this facet, those plugs will also appear here.",

        Edit_facets: "Edit facets",
        Facets: "Facets",
        No_facets: "No facets",
        New_profile_facet: "New profile facet",
        New_facet: "New facet",
        View_all_facets: "View all facets",
        No_facets_yet: "No facets added yet",
        x_page_of: "X Page of",
        From_all_facets_of_me: "From all facets of me",

        Unpublished: "Unpublished",
        Published: "Published",
        In_review: "In review",
        being_reviewed: "Being reviewed...",
        pending_unpublish: "Unpublishing: pending...",

        No_plugs_yet: "No plugs yet.",
        No_posts_plugs_yet: "No posts, no plugs yet.",
        When_people_plug: "When people plug (amplify)",
        content_those_plugs_will_live_here: "'s content, their plugs will appear here.",
        content_those_plugs_will_live_here_facet: "'s content related to this facet, their plugs will appear here.",

        When_people_plug_me: "When people plug (amplify) your content, their plugs will appear here.",
        content_those_plugs_will_live_here_me: "",

        When_people_plug_facet_me: "When people plug (amplify) your content related to this facet, their plugs will appear here.",

        Post_of_one: "",
        Post_of_two: "'s posts (profile posts and story page posts) will appear here.",
        Post_of_three: "When ",
        Post_of_four: " plugs (amplifies) his/her posts or other people posts, his/her plugs will also appear here.",

        Post_of_facet_one: "",
        Post_of_facet_two: "'s posts (profile posts and story page posts) will appear here.",
        Post_of_facet_three: "When ",
        Post_of_facet_four: " plugs (amplifies) his/her posts or other people posts, his/her plugs will also appear here.",

        Post_of_me_one:	"Your posts (profile posts and story page posts) will appear here.",
        Post_of_me_two: "When you plug (amplify) your posts or other people posts, your plugs will also appear here.",

        Post_of_me_facet_one:	"Your posts (profile posts and story page posts) related to this facet will appear here.",
        Post_of_me_facet_two: "When you plug (amplify) your posts or other people posts and related that plugs to this facet, those plugs will also appear here.",

        Post_published: "Post published",
        Published: "Published",
        Posts: "Posts",
        Visits: "Visits",

        reaction: "reaction",
        reactions: "reactions",

        This_story_page_has_no_posts_yet: "This Story Page has no posts yet.",
        No_posts_to_show: "No posts to show.",
        To_see_some_posts_here: "To see some posts here, you have to start following people.",

        Enter_story_page: "Enter Story Page",

        Download: "Download",

        Live_video_on_debyoo: "Live video on Debyoo",
        Live_audio_on_debyoo: "Live audio on Debyoo",
        Live_video: "Live video",
        Live_audio: "Live audio",
        Start_live_verb: "Start",
        Being_live: "Live",
        End_live_verb: "End",
        Checking_connection: 'Checking connection',
        Youre_live_now: "You're live now",
        Were_having_trouble_starting_your_live_video: "We're having trouble starting your live video.",
        Were_having_trouble_starting_your_audio_video: "We're having trouble starting your live audio.",
        Live_video_ended: "Live video ended",
        Live_audio_ended: "Live audio ended",
        viewers: "viewers",
        viewer: "viewer",
        listeners: "listeners",
        listener: "listener",
        Peak: "Peak",
        joined_live_video: "joined",
        joined_live_audio: "joined",
        Signin_to_watch_this_live_video: "Sign in to watch this live video",
        Signin_to_comment: "Sign in to comment",
        Download_debyoo_to_watch_this_live_video:  "Download Debyoo app to watch this live video",
        Were_having_trouble_streaming_this_right_now: "We're having trouble streaming this right now.",
        There_is_an_issue_with_the_connection: "There is an issue with the broadcaster's connection.",
        There_is_an_issue_with_your_connection: "There is an issue with the connection.",
        Lives: "Lives",
        Lives_and_spots: "Lives & Spots",

        Your_comment: "Your comment...",
        Link_copied_to_clipboard: "Link copied to clipboard",
        Copy_link: "Copy Link",

        Publish_a_post: "Publish a Post",
        Create_a_story_page: "Create a Story Page",
        Create_a_song_page: "Create a Song Page",
        Song_title: "Song title",
        Song_cover_photo: "Song cover photo",
        Publish_directly_on_your_profile: "Publish directly on your profile",
        Pulish_directly_on_the_selected_profile: "Pulish directly on the selected profile",
        Text: "Text",
        Photo: 'Photo',
        Video: "Video",
        Mute: "Mute",
        Unmute: "Unmute",
        Title: "Title",
        Description: 'Description',
        Enter_text_here: "Enter text here",
        Enter_location_here: "Enter location here",
        Post: "Post",

        Like: "Like",
        Happy: 'Happy',
        Sad: "Sad",
        Angry: 'Angry',
        Inspired: "Inspired",

        Your_shopping_basket_is_empty: "Your shopping basket is empty",
        Shopping_basket: "Shopping basket",

        is_currently_not_available_in_your_region: "is currently not available in your region.",
        is_currently_not_accessibble_in_your_region: "is currently not accessible in your region.",
        will_be_available_in_your_region_soon: "will be available in your region soon.",
        Dchat_web_will_be_available_soon: "dChat Web will be available soon.",
        Activate_dchat_in_the_debyoo_app_on_ios_or_android: "To access dChat Web, you need to activate dChat in the Debyoo app on iOS or Android first.",

        no_bloqued_profiles: "No Bloqued Profiles",

        No_notifications: "No notifications",
        News_feed: "News feed",

        CARD_NUMBER: "CARD NUMBER",
        EXP_DATE: "EXP. DATE",

        Wallet: "Wallet",
        Your_debyoo_wallet_is_empty: "Your Debyoo Wallet is empty",
        Delete_card_and_leave_yij_community: "Delete card and leave the Youth Initiative Jeunesse community",
        Change_category: "Change category",
        Delete_card: "Delete card",
        All_of_communitys_benefits: "All of community’s benefits and services are only available to Youth Initiative Jeunesse members.",
        If_you_delete_the_card_and_leave: "If you delete the card and leave the community, all the benefits will be lost.",
        We_delay_card_deletion_a_few_days_after: "We delay deletion a few days after it's requested. You can cancel a deletion request during this time.",
        It_may_take_up_to_30_days_to_delete: "It may take up to 15 days to delete Youth Initiative Jeunesse membership card. Your card is deactivated during this time.",
        If_you_delete_your_card:  "If you delete your card",
        Card_deletion_in_progress: "Card deletion in progress...",
        Cancel_the_detetion: "Cancel the deletion",
        Your_card_deletion_process_is_not_finished_yet: "Your card deletion process is not finished yet.",
        If_you_wish_to_cancel_the_card_deletion: "If you wish to cancel the deletion, tap «Reactivate Now»",
        Change_card_categ_warning: "Request for change of category is subject to a validation procedure by the Youth Initiative Jeunesse Team. You will be notified when the change has been validated.",
        Current_category: "Current category",
        Change_to: "Change to",
        Enter_your_password_then_tap_change_category: "Enter your password then tap «Change category»",
        You_will_be_notified_when_it_is_approved: "You will be notified when it is approved",
        Your_request_has_been_successfully_submitted: "Your request has been successfully submitted",
        yinitj_approved_message: "Your Youth Initiative Jeunesse membership request has been approved. Your digital membership card is now available.",
        yinitj_categ_changed_message: "Youth Initiative Jeunesse: Your request for change of category has been approved.",
        yinitj_deleted_message:  "Your membership card has been deleted. You are no longer part of the Youth Initiative Youth community.",
        Dreamer: "Dreamer",
        Initiator: "Initiator",
        Elder: "Elder",
        dreamer: "Dreamer",
        initiator: "Initiator",
        elder: "Elder",
        As_a_dreamer: "As a Dreamer",
        As_an_initiator: "As an Initiator",
        As_an_elder: "As an Elder",
        A_dreamer: "A Dreamer",
        An_initiator: "An Initiator",
        An_elder: "An Elder",
        Card_reactivated: "Card reactivated",

        Quit: "Quit",
        Search: "Search",
        or: "or",
        press_the_esc: "press Esc",

        Explore: "Explore",

        Your_search: "Your search",
        Recent_searches: "Recent searches",
        returned_no_results: "returned no results.",
        See_all_results_for: "See all results for",
        Search_Results: "Search Results",

        Upload_profile_photo: "Upload picture",
        Profile_picture: "Profile picture",

        New_story_page: "New Story Page",
        Enter_source_url_here: "Enter source URL here",
        Enter_title_here: "Enter title here",
        Enter_url_here: "Enter URL here",
        required: "required",
        optional: "optional",
        Story_title: "Story Page Title",
        Select: "Select",
        Category: "Category",
        Privacy: "Privacy",
        Public: "Public",
        Visible_only_by_me: "Visible only by me",

        Unpublished: "Unpublished",
        In_review: "In review",
        Unpublish_confirmation: "Unpublish confirmation",
        Publish_confirmation: "Publish confirmation",
        Unpublish_short: "Unpublish",
        Publish_short: "Publish",
        Unpublish: "Unpublish",
        Publish: "Publish",
        Are_you_sure_you_want_to_unpublish_this_storypage: "Are you sure you want to unpublish this story page?",
        Are_you_sure_you_want_to_unpublish_this_songpage: "Are you sure you want to unpublish this song page?",
        Are_you_sure_you_want_to_unpublish_this_shoppage: "Are you sure you want to unpublish this shop page?",
        Are_you_sure_you_want_to_publish_this_storypage: "Are you sure you want to publish this story page?",
        Are_you_sure_you_want_to_publish_this_songpage: "Are you sure you want to publish this song page?",
        Are_you_sure_you_want_to_publish_this_shoppage: "Are you sure you want to publish this shop page?",
        Are_you_sure_you_want_to_delete_this_storypage: "Are you sure you want to delete this story page?",
        Are_you_sure_you_want_to_delete_this_songpage: "Are you sure you want to delete this song page?",
        Are_you_sure_you_want_to_delete_this_post: "Are you sure you want to delete this post?",

        Delete: "Delete",

        Story_page_picture: "Story Page Picture",
        Create: "Create",
        Create_the_story_page: "Create the Story Page",
        Edit_story_page: "Edit Story Page",
        Edit: "Edit",
        Saving_changes: "Saving changes",
        New_post: "New post",
        Add_post_to_the_story_page: "Add post to the Story Page",
        Sending_the_post: "Sending the post",
        Edit_post: "Edit post",

        Preview_of_the_story_page: "Preview of the Story Page",
        Upload_story_page_thumbnail: "Upload Story Page Thumbnail",
        Change_story_page_thumbnail: "Change Story Page Thumbnail",
        Upload_song_page_thumbnail: "Upload Song Page Thumbnail",
        Change_song_page_thumbnail: "Change Song Page Thumbnail",
        Upload_shop_page_thumbnail: "Upload Shop Page Thumbnail",
        Change_shop_page_thumbnail: "Change Shop Page Thumbnail",
        Upload_photos: "Upload photos",
        Upload_photo: "Upload photo",
        Upload_videos: "Upload videos",
        Upload_video: "Upload video",
        Next_photo: "Next photo",
        Next_video: "Next video",
        Processing_new_media: "Processing new media...",
        Trim: "Trim",
        Crop: "Crop",


        Highly_recommended: 'Highly recommended',
        Creating_the_story_page: "Creating the Story Page",
        Creating_the_song_page: "Creating the Song Page",
        Creating_the_shop_page: "Creating the Shop Page",

        Select_a_facet: "Select a Facet",
        Select_facet: "Select profile facet",
        You_havent_added_any_facets_yet: "You haven't added any Facets to your profile yet.",
        Add_facets_to_your_profile: "Add Facets to your profile",
        Select_an_artist: "Select an Artist",
        You_havent_added_any_artists_yet: "You haven't added any Artists yet. You can add artists from Debyoo Play Manager.",
        Clear: "Clear",
        Relate_the_post_to_the_selected_facet: "Relate the post to the selected facet",

        Share_to_your_profile: "Share to your profile",
        Share_to_ones_profile: "Share to profile",
        Share_also_to_your_profile: "Share also to your profile",
        Share_to_artist_profile: "Share to artist profile",

        Loading_the_post_in_its_context: "Loading the post in its context...",
        View_the_plugged_story: "View the story page that has been plugged",
        View_the_plugged_post: "View the post that has been plugged",
        View_the_post_in_its_context: "View the post in its context",
        Loading_the_plugged_post_in_its_context: "Loading the post that has been plugged, in its context..",

        View_all_plugs: "View all Plugs",
        Plug_story_page_amplify_story_page: "Plug Story Page = Amplify Story Page",

        Visits: "Visits",
        reaction: "reaction",
        reactions: "reactions",
        Reactions: "Reactions",
        No_reactions: "No reactions",
        Replying_to: "Replying to",

        comment_plural: 'comments',
        comment_single: 'comment',
        View_all_cmts: "View all",

        no_followers: "No followers",
        following_no_one: "Following no one",

        Sort: "Sort",
        Sort_story_pages: "Sort Story Pages",
        Sort_posts: "Sort Posts",
        Sorting: "Sorting",
        Oldest_to_newest: "Oldest to newest",
        Newest_to_oldest: "Newest to oldest",
        Date_of_creation: "Date of creation",
        Date_of_occurrence: "Date of occurrence",
        Date_of_occurrence_short: "Date of occ.",
        Location_post: "Location",
        Location_post_short: "Location",
        View_all_story_pages: "View all Story Pages",
        No_story_pages: "No Story Pages",
        Created: "Created",
        Add_to_songpage: "Add to Song Page",
        Add_to_storypage: "Add to Story Page",
        BC_before_christ: "BC (before Christ)",
        Time_hour: "Time",
        Era: "Era",
        Period: "Period",
        Subperiod: "Subperiod",
        AD_anno_domini: "AD (anno Domini)",
        Standard: "Standard",
        Year: "Year",
        Month: "Month",
        Day: "Day",
        Time: "Time",
        Decade: "Decade",
        Century: "Century",
        Millennium: "Millennium",
        Early: "Early",
        Middle: "Middle",
        Late: "Late",

        Internationalization: "Internationalization",

        // STORY PAGE Categories
        art: "Art",
        automotive: "Automotive",
        beauty: "Beauty",
        biography: "Biography",
        birth: "Birth",
        book: "Book",
        brand: "Brand",
        business: "Business",
        cause: "Cause",
        cinema: "Cinema",
        civicrights: "Civic rights",
        culturecivilizationpeoples: "Peoples, Civilizations & Cultures",
        design: "Design",
        diary: "Diary",
        economy: "Economy",
        education: "Education",
        entertainment: "Entertainment",
        event: "Event",
        family: "Family",
        fashion: "Fashion",
        fooddrink: "Food and Drink",
        fundraising: "Fund raising",
        funny: "Funny",
        game: "Game",
        gossip: "Gossip",
        globalwarning: "Global warming & Climate change",
        health: "Health",
        history: "History",
        job: "Job",
        legallaw: "Law and Legal",
        lifestyle: "Lifestyle",
        movie: "Movie",
        music: "Music",
        news: "News",
        photography: "Photography",
        politics: "Politics",
        portfolio: "Portfolio",
        product: "Product",
        relationship: "Relationship",
        religion: "Religion",
        resume: "Résumé",
        service: "Service",
        science: "Science",
        shopping: "Shopping",
        sport: "Sport",
        stripcartoon: "Strip Cartoon",
        technology: "Technology",
        television: "Television",
        travel: "Travel",
        wedding: "Wedding",

        // Facets
        History_new: "History",
        Governance: "Governance",
        Development_programme: "Development programmes",
        Cause_new: "Cause",
        Community_new: "Community",
        Religion_faith: "Religion & Faith",
        Politics_new: "Politics",
        Event_planning: "Event planning",
        Travel_tourism_hospitality: "Travel / Tourism / Hospitality",
        Book_industry: "Book industry",
        Fashion_and_beauty: "Fashion & Beauty",
        Film_industry: "Film industry",
        Education_new: "Education",
        Visual_arts: "Visual arts",
        Sportandfitness: "Sport & Fitness",
        News_and_Media: "Media & News",
        Finance: "Finance",
        Telecommunications: "Telecommunications",
        Food_and_beverage: "Food & Beverage",
        Automotive: "Automotive",
        Science_and_technology: "Science and technology",
        Startups_and_innovation: "Startups and innovation",
        Lawandlegal: "Law & Legal",
        Luxuryindustry: "Luxury industry",
        Humor_comedy: "Humor & Comedy",
        Architecture_and_interior_design: "Architecture & interior design",
        Agriculture: "Agriculture",
        Construction_and_engineering: "Construction and engineering",
  	    Industry_energy_and_mines: "Industry, energy and mines",
        Services: "Services",

        // Soft skills
  	Communication: "Communication",
  	Effective_communication: "Effective communication",
  	Active_listening: "Active listening",
  	Confidence: "Confidence",
  	Conflict_resolution: "Conflict resolution",
  	Negotiation: "Negotiation",
  	Public_speaking: "Public speaking",
  	Writing: "Writing",
  	Nonverbal_communication: "Nonverbal communication",
  	Empathy: "Empathy",
  	Persuasion: "Persuasion",
  	Emotional_intelligence: "Emotional intelligence",
  	Storytelling: "Storytelling",
  	Oral_presentation: "Oral presentation",
  	Clear_presentation_of_ideas: "Clear presentation of ideas",
  	Knowledge_transmission: "Knowledge transmission",
  	Reading_body_language: "Reading body language",
  	Visual_communication: "Visual communication",
  	Telephone_communication: "Telephone communication",

  	Problem_solving: "Problem solving",
  	Research: "Research",
  	Leadership: "Leadership",
  	Initiative_taking: "Initiative taking",
  	Sense_of_responsibility: "Sense of responsibility",
  	Risk_management: "Risk management",
  	Teamwork: "Teamwork",
  	Networking: "Networking",
  	Critical_thinking: "Critical thinking",
  	Analysis: "Analysis",
  	Decision_making: "Decision making",
  	Resourcefulness: "Resourcefulness",
  	Proactivity: "Proactivity",
  	Troubleshooting: "Troubleshooting",

  	Creativity: "Creativity",
  	Curiosity: "Curiosity",
  	Learning_from_others: "Learning from others",
  	Open_mindedness: "Open mindedness",
  	Taking_calculated_risks: "Taking calculated risks",
  	Innovation: "Innovation",
  	Experimentation: "Experimentation",

  	Adaptability: "Adaptability",
  	Consistency: "Consistency",
  	Optimism: "Optimism",
  	Flexibility: "Flexibility",
  	Enthusiasm: "Enthusiasm",
  	Cooperation: "Cooperation",
  	Patience: "Patience",
  	Growth_mindset: "Growth mindset",

  	Work_ethic: "Work ethic",
  	Attention_to_detail: "Attention to detail",
  	Integrity: "Integrity",
  	Persistence: "Persistence",
  	Time_management: "Time management",
  	Organization: "Organization",
  	Dependability: "Dependability",
  	Motivation: "Motivation",
  	Perseverance: "Perseverance",
  	Results_oriented: "Results oriented",
  	Priority_management: "Priority management",



    // Hard Skills
  	Search_engine_optimization: "Search Engine Optimization (SEO)",
  	Search_engine_marketing: "Search Engine Marketing (SEM)",
  	Search_engine_advertising: "Search Engine Advertising (SEA)",
  	Social_media_optimization: "Social Media Optimization (SMO)",
  	Craft_design: "Craft design",
  	Machine_learning: "Machine Learning",
  	Databases: "Databases",
  	Data_visualization: "Data Visualization",
  	Fashion_content_creator: "Fashion Content Creator",
  	Data_science: "Data Science",
  	Drawing_with_sewing_machine: "Drawing with Sewing Machine",
  	Business_know_how: "Business know-how",
  	Project_management: "Project Management",
  	Budgeting: "Budgeting",
  	Scheduling: "Scheduling",
  	Planning: "Planning",
  	Risk_management: "Risk Management",
  	Contract_management: "Contract Management",
  	Programming_languages: "Programming languages",
  	Editing: "Editing",
  	Post_production: "Post-Production",
  	Screenwriting: "Screenwriting",
  	Directing: "Directing",
  	Producing: "Producing",
  	Fashion_show_production: "Fashion Show Production",
  	Fashion_show_direction: "Fashion Show Direction",
  	Business_model_creation: "Business Model Creation",
  	Business_model_implementation: "Business Model Implementation",
  	Business_model_development: "Business Model Development",


  	// Activities
  	Curiosity: "Curiosity",
  	read_article: "read an article",
  	read_article_dyn: "read a %%%% article",
  	read_book: "read a book",
  	followed_user: "followed an user",
  	watched_video: "watched a video",
  	watched_video_dyn: "watched a %%%% video",
  	listened_podcast: "listened to a podcast",

  	Engagement: "Engagement",
  	attended_conference: "attended a conference",
  	attended_forum: "attended a forum",
  	attended_meeting: "attended a meeting",
  	attended_seminar: "attended a seminar",
  	joined_group: "joined a group",

    Music_preview: "Preview",
    Song: "Song",
  	Album: "Album",
    Recordings: "Recordings",
  	Performance_experience: "Performance experience",
    Work_experience: "Work experience",
    Awards_and_honors: "Awards and honors",
    Internship_and_training: "Internship and training",
  	Certifications: "Certifications",
  	Learning_and_training: "Learning & training",
  	Education_and_training: "Education and training",
  	earned_degree: "earned a degree",
  	earned_degree_dyn: "earned a %%%% degree",
  	obtained_certification: "obtained a certification",
  	obtained_certification_dyn: "obtained a %%%% certification",
  	obtained_licence: "obtained a licence",
  	obtained_licence_dyn: "obtained a %%%% licence",
  	is_attending_high_school: "is attending a high school",
  	is_attending_high_school_dyn: "is attending %%%%",
  	is_attending_college: "is attending a college",
  	is_attending_college_dyn: "is attending %%%%",
  	is_attending_university: "is attending an university",
  	is_attending_university_dyn: "is attending %%%%",
  	took_course: "took a course",
  	took_course_dyn: "took a %%%% course",
  	started_training: "started a training",
  	started_training_dyn: "started a %%%% training",
  	completed_training: "completed a training",
  	completed_training_dyn: "completed a %%%% training",
  	started_internship: "started an internship",
  	started_internship_dyn: "started a %%%% internship",
  	completed_internship: "completed an internship",
  	completed_internship_dyn: "completed a %%%% internship",
  	received_award: "received an award",
  	received_award_dyn: "received an award for %%%%",
  	received_honor: "received honors",
  	received_honor_dyn: "received honors: %%%%",
  	was_hired: "started a new job",
  	was_hired_dyn: "started a new job at %%%%",
  	appeared_on_song: "appeared on a song",
  	appeared_on_song_dyn: "appeared on the song «%%%%»",
  	appeared_on_album: "appeared on an album",
  	appeared_on_album_dyn: "appeared on the album «%%%%»",
  	performed_at_event: "performed at an event",
  	performed_at_event_dyn: "performed at the event of %%%%",
  	performed_a_job: "performed a job or task",
  	performed_a_job_dyn: "performed a job or task",
  	founded_an_entity: "founded an entity",
  	founded_an_entity_dyn: "founded: «%%%%»",
  	founded_an_entity_noun: "Founder",
  	created_an_object: "created an object",
  	created_an_object_dyn: "created: «%%%%»",
  	created_an_object_noun: "Creator",
  	coded_an_object: "coded an object",
  	coded_an_object_dyn: "coded: «%%%%»",
  	coded_an_object_noun: "Coder",
  	designed_an_object: "designed an object",
  	designed_an_object_dyn: "designed: «%%%%»",
  	designed_an_object_noun: "Designer",
  	developed_an_object: "developed an object",
  	developed_an_object_dyn: "developed: «%%%%»",
  	developed_an_object_noun: "Developer",
  	invented_an_object: "invented an object",
  	invented_an_object_dyn: "invented: «%%%%»",
  	invented_an_object_noun: "Inventor",
  	programmed_an_object: "programmed an object",
  	programmed_an_object_dyn: "programmed: «%%%%»",
  	programmed_an_object_noun: "Programmer",

    Showcased_a_collection: 'You showcased a collection',
    Modeled_for: 'You modeled for',
    Walked_the_runway: 'You walked the runway',
    Choreographed_a_show: 'You choreographed a show',
    Managed_front_stage: 'You managed front stage',
    Managed_back_stage: 'You managed back stage',
    Produced_a_fashion_show: 'You produced a fashion show',
    Directed_a_fashion_show: 'You directed a fashion show',
    Directed_a_casting: 'You directed a casting',
    Supervised_hairstyle: 'You supervised hairstyle',
    Assisted_hairstylist: 'You assisted hairstylist',
    Supervised_makeup: 'You supervised makeup',
    Assisted_makupartist: 'You assisted makupartist',
    Worked_as_a_staff_member: 'You worked as a staff member',
    Hosted_a_show: 'Hosted a show',
    Hosted_a_event: 'Hosted an event',
    Moderated_a_panel: 'Moderated a panel',

    Showcased_a_collection_noun: 'Designer/Stylist',
    Modeled_for_noun: 'Catalog model',
    Walked_the_runway_noun: 'Runway model',
    Choreographed_a_show_noun: 'Fashion show choreographer',
    Managed_front_stage_noun: 'Front stage manager',
    Managed_back_stage_noun: 'Backstage manager',
    Produced_a_fashion_show_noun: 'Fashion show producer',
    Directed_a_fashion_show_noun: 'Fashion show director',
    Directed_a_casting_noun: 'Casting director',
    Supervised_hairstyle_noun: 'Hair stylist',
    Assisted_hairstylist_noun: 'Hair stylist assistant',
    Supervised_makeup_noun: 'Makeup artist',
    Assisted_makupartist_noun: 'Makeup artist assistant',
    Worked_as_a_staff_member_noun: 'Staff member',
    Hosted_a_show_noun: 'Host',
    Hosted_a_event_noun: 'Host',
    Moderated_a_panel_noun: 'Moderator',


  	Application_of_knowledge: "Application of knowledge",
  	received_membership: "received a membership",
  	received_membership_dyn: "received a %%%% membership",

      // ALL_INSTRUMENTS_VOICES
      Voices: "Voices",
      Altovoice: "Alto voice",
      Baritonevoice: "Baritone voice",
      Bassvoice: "Bass voice",
      Castrato: "Castrato",
      Contraltovoice: "Contralto voice",
      Sopranovoice: "Soprano voice",
      Tenorvoice: "Tenor voice",
      Countertenorvoice: "Countertenor voice",
      Mezzosopranovoice: "Mezzo-soprano voice",

      // ALL_INSTRUMENTS_KEYBOARDS
      Keyboards: "Keyboards",
      Accordion: "Accordion",
      Celesta: "Celesta",
      Electricpiano: "Electric Piano",
      Hammondorgan: "Hammond Organ",
      Harpsichord: "Harpsichord",
      Keyboard: "Keyboard",
      Piano: "Piano",
      Pipeorgan: "Pipe Organ",
      Reedorgan: "Reed Organ",
      Synclavier: "Synclavier",
      Synthesizer: "Synthesizer",

      // ALL_INSTRUMENTS_STRINGS
      Strings: "Strings",
      Acousticguitar: "Acoustic Guitar",
      Banjo: "Banjo",
      Bassguitar: "Bass",
      Cello: "Cello",
      Contrabass: "Contrabass",
      Electricguitar: "Electric Guitar",
      Guitar: "Guitar",
      Harp: "Harp",
      Ukulele: "Ukulele",
      Viola: "Viola",
      Violin: "Violin",

      // ALL_INSTRUMENTS_PERCUSSIONS
      Percussions: "Percussions",
      Percussion: "Percussion",
      Beatboxing: "Beatboxing",
      Cabasa: "Cabasa",
      Drum: "Drum",
      Bongos: "Bongos",
      Castanets: "Castanets",
      Cowbell: "Cowbell",
      Conga: "Conga",
      Djembe: "Djembe",
      Marimba: "Marimba",
      Steeldrum: "Steel Drum",
      Timpani: "Timpani",
      Tambourine: "Tambourine",
      Vibraphone: "Vibraphone",
      Xylophone: "Xylophone",

      // ALL_INSTRUMENTS_BRASS
      Brass: "Brass",
      Baritone: "Baritone",
      Cornet: "Cornet",
      Flugelhorn: "Flugel Horn",
      Frenchhorn: "French Horn",
      Euphonium: "Euphonium",
      Trombone: "Trombone",
      Trumpet: "Trumpet",
      Tuba: "Tuba",

      // ALL_INSTRUMENTS_WOODWINDS
      Woodwinds: "Woodwinds",
      Bagpipe: "Bagpipe",
      Bassoon: "Bassoon",
      Contrabassoon: "Contrabassoon",
      Clarinet: "Clarinet",
      Bbclarinet: "Bb Clarinet",
      Bassclarinet: "Bass Clarinet",
      Flute: "Flute",
      Oboe: "Oboe",
      Ocarina: "Ocarina",
      Organ: "Organ",
      Panflute: "Pan Flute",
      Piccolo: "Piccolo",
      Harmonica: "Harmonica",
      Englishhorn: "English Horn",
      Recorder: "Recorder",
      Saxophone: "Saxophone",
      Sopranosaxophone: "Soprano Saxophone",
      Altosaxophone: "Alto Saxophone",
      Tenorsaxophone: "Tenor Saxophone",
      Baritonesaxophone: "Baritone Saxophone",

      // ALL_INSTRUMENTS_ELECTRONIC
      Computer_and_electronic: "Electronic",
      Computer: "Computer",
      Drummachine: "Drum Machine",
      Sampler: "Sampler",
      Djturntable: "DJ Turntable",

      // COUNTRIES
      // Countries
  "AF": "Afghanistan",
  "AX": "Aland Islands",
  "AL": "Albania",
  "DZ": "Algeria",
  "AS": "American Samoa",
  "AD": "Andorra",
  "AO": "Angola",
  "AI": "Anguilla",
  "AQ": "Antarctica",
  "AG": "Antigua And Barbuda",
  "AR": "Argentina",
  "AM": "Armenia",
  "AW": "Aruba",
  "AU": "Australia",
  "AT": "Austria",
  "AZ": "Azerbaijan",
  "BS": "Bahamas",
  "BH": "Bahrain",
  "BD": "Bangladesh",
  "BB": "Barbados",
  "BY": "Belarus",
  "BE": "Belgium",
  "BZ": "Belize",
  "BJ": "Benin",
  "BM": "Bermuda",
  "BT": "Bhutan",
  "BO": "Bolivia",
  "BA": "Bosnia And Herzegovina",
  "BW": "Botswana",
  "BV": "Bouvet Island",
  "BR": "Brazil",
  "IO": "British Indian Ocean Territory",
  "BN": "Brunei Darussalam",
  "BG": "Bulgaria",
  "BF": "Burkina Faso",
  "BI": "Burundi",
  "KH": "Cambodia",
  "CM": "Cameroon",
  "CA": "Canada",
  "CV": "Cape Verde",
  "KY": "Cayman Islands",
  "CF": "Central African Republic",
  "TD": "Chad",
  "CL": "Chile",
  "CN": "China",
  "CX": "Christmas Island",
  "CC": "Cocos (Keeling) Islands",
  "CO": "Colombia",
  "KM": "Comoros",
  "CG": "Congo",
  "CD": "Congo, Democratic Republic",
  "CK": "Cook Islands",
  "CR": "Costa Rica",
  "CI": "Cote D\"Ivoire",
  "HR": "Croatia",
  "CU": "Cuba",
  "CY": "Cyprus",
  "CZ": "Czech Republic",
  "DK": "Denmark",
  "DJ": "Djibouti",
  "DM": "Dominica",
  "DO": "Dominican Republic",
  "EC": "Ecuador",
  "EG": "Egypt",
  "SV": "El Salvador",
  "GQ": "Equatorial Guinea",
  "ER": "Eritrea",
  "EE": "Estonia",
  "ET": "Ethiopia",
  "FK": "Falkland Islands (Malvinas)",
  "FO": "Faroe Islands",
  "FJ": "Fiji",
  "FI": "Finland",
  "FR": "France",
  "GF": "French Guiana",
  "PF": "French Polynesia",
  "TF": "French Southern Territories",
  "GA": "Gabon",
  "GM": "Gambia",
  "GE": "Georgia",
  "DE": "Germany",
  "GH": "Ghana",
  "GI": "Gibraltar",
  "GR": "Greece",
  "GL": "Greenland",
  "GD": "Grenada",
  "GP": "Guadeloupe",
  "GU": "Guam",
  "GT": "Guatemala",
  "GG": "Guernsey",
  "GN": "Guinea",
  "GW": "Guinea-Bissau",
  "GY": "Guyana",
  "HT": "Haiti",
  "HM": "Heard Island & Mcdonald Islands",
  "VA": "Holy See (Vatican City State)",
  "HN": "Honduras",
  "HK": "Hong Kong",
  "HU": "Hungary",
  "IS": "Iceland",
  "IN": "India",
  "ID": "Indonesia",
  "IR": "Iran, Islamic Republic Of",
  "IQ": "Iraq",
  "IE": "Ireland",
  "IM": "Isle Of Man",
  "IL": "Israel",
  "IT": "Italy",
  "JM": "Jamaica",
  "JP": "Japan",
  "JE": "Jersey",
  "JO": "Jordan",
  "KZ": "Kazakhstan",
  "KE": "Kenya",
  "KI": "Kiribati",
  "KR": "Korea",
  "KP": "North Korea",
  "KW": "Kuwait",
  "KG": "Kyrgyzstan",
  "LA": "Lao People\"s Democratic Republic",
  "LV": "Latvia",
  "LB": "Lebanon",
  "LS": "Lesotho",
  "LR": "Liberia",
  "LY": "Libyan Arab Jamahiriya",
  "LI": "Liechtenstein",
  "LT": "Lithuania",
  "LU": "Luxembourg",
  "MO": "Macao",
  "MK": "Macedonia",
  "MG": "Madagascar",
  "MW": "Malawi",
  "MY": "Malaysia",
  "MV": "Maldives",
  "ML": "Mali",
  "MT": "Malta",
  "MH": "Marshall Islands",
  "MQ": "Martinique",
  "MR": "Mauritania",
  "MU": "Mauritius",
  "YT": "Mayotte",
  "MX": "Mexico",
  "FM": "Micronesia, Federated States Of",
  "MD": "Moldova",
  "MC": "Monaco",
  "MN": "Mongolia",
  "ME": "Montenegro",
  "MS": "Montserrat",
  "MA": "Morocco",
  "MZ": "Mozambique",
  "MM": "Myanmar",
  "NA": "Namibia",
  "NR": "Nauru",
  "NP": "Nepal",
  "NL": "Netherlands",
  "AN": "Netherlands Antilles",
  "NC": "New Caledonia",
  "NZ": "New Zealand",
  "NI": "Nicaragua",
  "NE": "Niger",
  "NG": "Nigeria",
  "NU": "Niue",
  "NF": "Norfolk Island",
  "MP": "Northern Mariana Islands",
  "NO": "Norway",
  "OM": "Oman",
  "PK": "Pakistan",
  "PW": "Palau",
  "PS": "Palestinian Territory, Occupied",
  "PA": "Panama",
  "PG": "Papua New Guinea",
  "PY": "Paraguay",
  "PE": "Peru",
  "PH": "Philippines",
  "PN": "Pitcairn",
  "PL": "Poland",
  "PT": "Portugal",
  "PR": "Puerto Rico",
  "QA": "Qatar",
  "RE": "Reunion",
  "RO": "Romania",
  "RU": "Russian Federation",
  "RW": "Rwanda",
  "BL": "Saint Barthelemy",
  "SH": "Saint Helena",
  "KN": "Saint Kitts And Nevis",
  "LC": "Saint Lucia",
  "MF": "Saint Martin",
  "PM": "Saint Pierre And Miquelon",
  "VC": "Saint Vincent And Grenadines",
  "WS": "Samoa",
  "SM": "San Marino",
  "ST": "Sao Tome And Principe",
  "SA": "Saudi Arabia",
  "SN": "Senegal",
  "RS": "Serbia",
  "SC": "Seychelles",
  "SL": "Sierra Leone",
  "SG": "Singapore",
  "SK": "Slovakia",
  "SI": "Slovenia",
  "SB": "Solomon Islands",
  "SO": "Somalia",
  "ZA": "South Africa",
  "GS": "South Georgia And Sandwich Isl.",
  "ES": "Spain",
  "LK": "Sri Lanka",
  "SD": "Sudan",
  "SR": "Suriname",
  "SJ": "Svalbard And Jan Mayen",
  "SZ": "Swaziland",
  "SE": "Sweden",
  "CH": "Switzerland",
  "SY": "Syrian Arab Republic",
  "TW": "Taiwan",
  "TJ": "Tajikistan",
  "TZ": "Tanzania",
  "TH": "Thailand",
  "TL": "Timor-Leste",
  "TG": "Togo",
  "TK": "Tokelau",
  "TO": "Tonga",
  "TT": "Trinidad And Tobago",
  "TN": "Tunisia",
  "TR": "Turkey",
  "TM": "Turkmenistan",
  "TC": "Turks And Caicos Islands",
  "TV": "Tuvalu",
  "UG": "Uganda",
  "UA": "Ukraine",
  "AE": "United Arab Emirates",
  "GB": "United Kingdom",
  "US": "United States",
  "UM": "United States Outlying Islands",
  "UY": "Uruguay",
  "UZ": "Uzbekistan",
  "VU": "Vanuatu",
  "VE": "Venezuela",
  "VN": "Vietnam",
  "VG": "Virgin Islands, British",
  "VI": "Virgin Islands, U.S.",
  "WF": "Wallis And Futuna",
  "EH": "Western Sahara",
  "YE": "Yemen",
  "ZM": "Zambia",
  "ZW": "Zimbabwe",
      }
    },
    fr: {
      translation: {
        Journalist: 'Journaliste',
        Reporter: 'Reporter',
        Columnist: 'Chroniqueur',
        Blogger: 'Bloggueur',
        writtenPress: 'Presse écrite',
        onlinePress: 'Presse en ligne',
        radioPress: 'Presse radio',
        tvPress: 'Presse TV',
        Personal_profile: "Profil personnel",
        Brand: "Marque",
      	Company: "Société",
        Institution: "Institution",
      	Organization: "Organisation",
      	Association: "Association",
      	Sports_team: "Equipe sportive",
      	Church: "Eglise",
      	Religious_organization: "Organisation religieuse",
      	Place_lieu: "Lieu",
        Event: "Evénement",
        Account: "Compte",
        General_question: "Question générale",
        Hi: "Haut",
        High: "Haut",
        Low: "Bas",
        Mid: "Moyen",
        marked_as_resolved: "a marqué ce cas comme résolu",
        opened_a_case: "a ouvert un nouveau cas de support",
        closed_this_case: "a clôturé ce cas de support",
        auto_closed_this_case: "Ce cas de support a été clôturé automatiquement",
        reopened_this_case: "a réouvert ce cas de support",
        connected_to_serve_you: "s'est connecté(e) pour servir",
        Dashboard: "Tableau de bord",
        Feed: "Fil d'actualités",
        Legal_documents: "Contrats & Documents légaux",
        Contacts_and_support: "Contacts & Support",
        Analytics: "Analytics",
        Sandbox: "Sandbox",
        Admins: "Administrateurs",
        Contact_persons: "Personnes de contact",
        Business_models: "Modèles économiques",
        Search_noun: "Recherche",
        Place_4dresume: "CV-4D du lieu",
        More_about: "En savoir plus",
        Personality: 'Personnalité',
      	Experience_and_skills: "Expérience et compétences",
      	Social_impact: "Impact social",
      	The_personality: 'La personnalité',
      	The_experience_and_skills: "L'expérience et les compétences",
      	The_storytelling: "Le storytelling",
      	The_social_impact: "L'impact social",
      	Jobs: 'Jobs',
      	Job: 'Job',
      	Student_job: "Job étudiant",
      	Student_jobs: "Jobs étudiants",
      	Apply_to_job: "Postulez",
      	Paid_intership: "Stage rémunéré",
        Careers: "Carrières",
        enter_flooz_pin_code: "Un SMS de vérification vous a été envoyé. Veuillez l'ouvrir et saisir votre code de sécurité Flooz pour confirmer cette transaction.",
        enter_tmoney_pin_code: "Un SMS de vérification vous a été envoyé. Veuillez l'ouvrir et saisir votre code de sécurité TMoney pour confirmer cette transaction.",
        Reason: "Raison",
        Insufficient_funds: "Fonds insuffisants",
        Pay_now: "Payer maintenant",
        Please_enter_the_name: "Veuillez entrer le nom",
        Card_holder_name: "Nom du titulaire de la carte",
        Pay_with: "Payer avec",
        Your_moov_number: "Votre numéro Moov",
        Your_togocom_number: "Votre numéro Togocom",
        Your_vodacom_number: "Votre numéro Vodacom",
        Your_orange_number: "Votre numéro Orange",
        You_have_made_a_donation: "Vous avez fait un don",
        Internal_error_message: "Désolé, une erreur est survenue. Veuillez réessayez plus tard.",
        Payment_successful: "Paiement effectué avec succès",
        Donations_are_tax_deductible: "Les dons sont déductibles fiscalement",
        This_donations_will_not_be_tax_deductible: "Ce don ne pourra pas être déductible fiscalement",
        Beneficiary: "Bénéficiaire",
        Donation_amount: "Montant du don",
        Anonymous: "Anonyme",
  	Other_amount: "Autre montant",
  	Change_the_amount: "Changer le montant",
  	Donations_are_tax_deductible: "Les dons sont déductibles fiscalement",
  	Fundraising: "Collecte de fonds",
  	Enabled_m: "Activé",
  	Disabled_m: "Désactivé",
  	Enabled_f: "Activée",
  	Disabled_f: "Désactivée",
  	Intenational_non_governmental_organization: "Organisation non gouvernementale internationale",
  	Non_governmental_organization: "Organisation non gouvernementale",
  	Charity: "Organisation caritative",
  	Non_profit_organization: "Association à but non lucratif",
  	Type_of_entity: "Type d'entité",
  	Want_to_enable_permanent_fundraising_on_your_profile: "Vous souhaitez activer la collecte de fonds de manière permanente sur votre profil?",
  	Apply_to_enable_fundraising: "Activer de collecte de fonds",
  	Enabling_fundraising_permanently: "Activation de collecte de fonds sur votre profil de manière permanente",
  	Fundraising_permanently: "Collecte de fonds sur votre profil de manière permanente",
  	Pending_application: "Demande en attente",
  	Permanent_fundraising_on_your_profile_enabled: "Collecte de fonds permanente sur votre profile activée",
  	Fundraising_reports: "Rapports de collecte de fonds",
  	Your_payment_was_declined: "Votre paiement a été refusé",
  	Your_aborted_the_transaction: "Vous avez annulé la transaction",
  	Use_another_payment_method: "Utilisez un autre mode de paiement",
  	Thank_you_for_the_donation: "Merci pour le don.",
  	We_have_sent_you_an_email_confirming_the_details_of_your_donation: "Nous vous avons envoyé un e-mail confirmant les détails de votre don.",

        Payment_methods: "Modes de paiement",
  	Payment_method: "Mode de paiement",
  	Change_payment_method: "Changer le mode de paiement",
  	Select_a_new_payment_method: "Sélectionner un nouveau mode de paiement",
  	Choose_another_payment_method: "Choisir un autre mode de paiement",
  	Choose_verb: "Choisir",
  	Pay_with_bancontact: "Payer avec Bancontact",
  	Connecting_with_bancontact: "En train de se connecter avec Bancontact",
  	Connecting_with_paypal: "En train de se connecter avec PayPal",
  	Pay_with_a_card: "Payer avec une carte",
  	Pay_with_paypal: "Payer avec Paypal",
  	Pay_with_flooz: "Payer avec Flooz",
  	Pay_with_tmoney: "Payer avec TMoney",
  	please_verify_your_mobile_payment_information: "Veuillez vérifier votre numéro de téléphone et/ou vérifiez que vous avez suffisamment de fonds pour l'achat",
  	Select_a_payment_method: "Sélectionnez un mode de paiement",
  	Credit_debit_card: "Carte de Crédit/Débit",
  	Enter_your_moov_number: "Entrez votre numéro Moov",
  	Enter_your_togocel_number: "Entrez votre numéro Togocel",
  	Done: "Fait",
  	New_story: "Nouvelle story page",
  	Library: "Médiathèque",
  	Cart: "Panier",
  	Messages: "Messages",
  	Give_credit: "Créditer",
  	Request_credit: "Réclamer un Crédit",
  	New_plug: "Nouvelle Amplification",
  	Shares: "Partages",
  	Share_instantly: 'Partager instantaténement',
  	Post_from_this: "Publier à partir de ceci",
  	Post_published_m: "Publié",
  	Place_order: "Effectuer le paiement",
  	Verify_your_moov_number: "Vérifiez votre numéro Moov",
  	Verify_your_togocel_number: "Vérifiez votre numéro Togocel",
          Your_order_has_been_received: "Votre commande a été passée",
          Thank_you_for_your_purchase: "Merci pour votre achat!",
          Your_order_number_is: "Votre numéro de commande est",
  				Order_number: "# de commande",
  				Order_number_short: "# commande",
  				Remove_card: "Retirer une carte",
          You_will_receive_an_order_confirmation: "Nous vous avons envoyé un message électronique de confirmation contenant les détails de votre commande.",

        Select_a_payment_method: "Sélectionnez un mode de paiement",
        Your_are_about_to_donate: "Vous êtes sur le point de faire un don",
        Review_donation: "Récapitulatif",
        Share_verb: "Partager",
        Select_one: "Sélectionner l'une des options",
        Myself_only: "Je suis le seul employé (entreprise unipersonnelle)",

        About_me: "A propos de moi",
      	City: "Ville",
      	Birthdate: "Date de naissance",
      	Gender: "Sexe",
      	Contact: "Contact",
      	years_active: "En activité",
      	Instruments: "Instruments",
      	Measurements: "Mensurations",
      	Type_of_modeling: "Type de mannequin",
      	Fabric: "Tissu",
      	Product: "Product",
      	Category: "Catégorie",
      	Type: "Type",
      	Position: "Position",
      	Genres: "Genres",
      	Topics: "Thèmes",
      	Sport: 'Sport',
      	Occupation: "Profession/Rôle",
      	Field: "Domaine",
      	Title: "Titre",
      	male: "Homme",
      	female: "Femme",
        Eyes: "Yeux",
      	About_shoe: "Pointure (Chaussure)",
      	Height: "Taille (Hauteur)",
        Chest: "Tour de poitrine",
        Bust: "Tour de poitrine",
        Waist: "Tour de taille",
        Hips: "Tour des hanches",
      	Type_of_modeling: "Type de mannequin",
      	Products: "Produits",
      	Product: "Produit",
      	Fabric: "Tissu",
      	Fabrics: "Tissus",
        Brands: "Marques",
  		  Entity_Brands: "Entité/Marques",
  	    Specialities: "Specialités",
  	    Career_metier: "Métier",
        Specialities_careers: "Spécialités/Métiers",
  		  MMYY: "MM/AA",

      	 Brand: "Marque",
      	 Company: 'Entreprise',

      	Year_of_birth_not_public: "Année de naissance masquée",
      	Not_public: "Masqué",

        Click_on_any_of_the_facets_to_discover_more: "Cliquer sur l'une des facettes pour en découvrir plus",
        Tap_any_of_the_facets_to_discover_more: "Appuyer sur l'une des facettes pour en découvrir plus",
        related_to_this_facet: 'relative à cette facette',
        We_re_rethinking_resume_and_cv: "Nous réimaginons le curriculum vitae (CV).",
      	There_is_no_content_available: "Aucun contenu n'est disponible pour le moment",
      	This_xwall_has_no_content_yet: "Ce X-Wall (Mur d'eXpériences) n'a aucun contenu pour le moment.",
      	This_xpage_has_no_content_yet: "Cette X Page (Page d'eXpériences) n'a aucun contenu pour le moment.",
      	All_your_experience_skills_and_credits_me: "Tous vos expériences (éducation, apprentissage, formation, travail...), compétences et crédits relatifs à la facette sélectionnée apparaîtront ici.",
      	All_your_global_experience_skills_and_credits_me: "L'ensemble de tout vos expériences (éducation, apprentissage, formation, travail...), compétences et crédits apparaîtra ici.",
      	All_your_experience_skills_and_credits_start: "Tous les expériences (éducation, apprentissage, formation, travail...), compétences et crédits de",
      	All_your_experience_skills_and_credits_end: " relatifs à la facette sélectionnée apparaîtront ici.",
      	All_your_global_experience_skills_and_credits_end: " apparaîtront ici.",
      	will_appear_here: "will appear here.",

        Posted: "Postée",
        Expected: "Prévu",
        Facet_successfully_added: "Facette ajoutée avec succès",
        Facet_deleted: "Facette supprimée",
        with_a_story_page: "avec une story page",
        with_an_audio: "avec un audio",
        with_a_photo: "avec une photo",
        with_a_text: "avec un texte",
        with_a_video: "avec une vidéo",
        with_a_link: "avec un lien",
        Plug_successfully_added: "Plug ajouté avec succès",
        Delete_this_plug: "Supprimer ce Plug",
        Are_you_sure_you_want_to_delete_this_plug: "Êtes-vous sûr de vouloir supprimer ce plug?",
        Search_and_then_select: "Rechercher et puis sélectionner dans les résultats",
        Search_and_then_select_the_story_page: "Rechercher et puis sélectionner la story page",
        Press_to_record_audio: "Appuyer pour enregistrer l'audio",
        Stop: "Stop",
        Type_here_to_search: "Saisir la recherche ici",
        Confirm_the_attachment: "Confirmer l'annexe",
        Remove_the_attachment: "Retirer l'annexe",
        Remove_attachment: "Retirer un annexe",
        No_attachments: "Aucun annexe",
        Attachment_successfully_added: "Annexe ajouté avec succès",
        Attach_story_post: "Annexer Story Page/Post",
        Are_you_sure_you_want_to_remove_the_attachment: "Êtes-vous sûr de vouloir retirer l'annexe?",
        Soft_skill_successfully_added: "Compétence humaine ajoutée avec succès",
        Hard_skill_successfully_added: "Compétence technique ajoutée avec succès",
        Activity_statement_successfully_added: "Activité ajoutée avec succès",
        Education_formation: "Formation",
        Graduation_date: "Date d'obtention",
        Graduation_expected_date: "Date prévue d'obtention",
        Get_direction: "Afficher l'itinéraire",
        Schools_around_me: "Ecoles autour de moi",
        Radius: "Rayon",
        Change_radius: "Changer le rayon",
        Sorry_no_results_found: "Désolé, aucun résultat n'a été trouvé",
        Type_of_school: "Type d'école",
        Classic: "Classique",
        International: "International",
        International_fem: "Internationale",
        Private_fem: "Privée",
        Public_fem: "Publique",
        Search_filters: "Filtres de recherche",
        Apply: "Appiquer",
        Innovation_or_classic: "Innovation ou classique?",
        Save_with_unlisted_neighb: "Sauvegarder sans la liste",
        Listed_in_alphabetical_order: "Liste par ordre alphabétique",
        Players_in_the_music_industry: "Les acteurs du business de la musique",
        Players_in_the_agrobusiness: "Les acteurs de l'agrobusiness",
        Add_activity_statement: "Ajouter une activité",
        Activity_statement: "Activité",
        Activity_statements: "Activités",
        Add_skill: "Ajouter une compétence",
        Soft_skill: "Compétence humaine",
        Hard_skill: "Compétence technique",
        Soft_skills: "Compétences humaines",
        Hard_skills: "Compétences techniques",
        Add_a_skill_or_an_activity: "Ajouter une compétence ou une activité",
        Relate_it_to_one_or_more_facets: "Relate cela à une ou plusieurs facettes",
        Relate_it_to_one_or_more_of_your_facets: "Relater cela à une ou plusieurs de mes facettes",
        Relate_the_selected_skill_to_one_or_more_of_your_facets: "Relater la compétence sélectionnée à une ou plusieurs de mes facettes",
        No_facets_has_been_added_yet: "Pas encore ajouté au moins une facette?",
        Will_be_related_only_to_the_selected_facet: "Sera relative seulement à la facette sélectionnée",
        The_selected_skill_is_related_to_the_current_facet_only: "La compétence sélectionnée est relative à la facette sélectionnée uniquement",
        No_soft_skills_added_yet: "Aucune compétence humaine ajoutée pour le moment",
        No_hard_skills_added_yet: "Aucune compétence technique ajoutée pour le moment",
        No_activity_statements_yet: "Il n'y a aucune activité à afficher pour le moment",
        Person: "Personne",
        Action: "Action",
        Object: "Objet",
        Choose_an_action: "Choisir une action",
        Define_the_action: "Définir l'action",
        Define_the_object: "Définir l'objet",
        Enter_the_target: "Entrer la cible",
        Choose_the_verb: "Choisir le verbe + le complément",
        Target_plus_verb: "(Cible + Verbe)",
        Target: "Cible",
        Verb: "Verbe",
        Type_plus_verb: "(Verbe + complément + opérateur)",
        Type_plus_verb_en: "(Verbe + opérateur + complément)",
        Verb_complement: "Verbe + complément",
        Change_verb: "Changer le verbe",
        Type_of_activity: "Type d'activité",
        Type_of_object: "Type d'objet",
        Type_of_job_or_task: "Type de travail ou tâche",
        Choose_a_sector: "Choisir un secteur",
        Choose_the_type_of_object: "Choisir le type d'objet",
        Choose_the_type_of_job_or_task: "Choisir le type de travail ou tâche",
        Name_of_the_object: "Nom de l'objet",
        Examples_provider_job_task: "Ex: Nom de la marque, Nom de la compagnie, Nom de l'événement...",
        Name_of_activity: "Nom de l'opérateur",
        Enter_the_type_of_activity: "Entrer le type d'activité",
        Enter_the_name_of_activity: "Entrer le nom de l'opérateur",
        Type_of_activity_you_interacted_with: "Type d'activité vous avez interagi avec",
        Name_of_activity_you_interacted_with: "Nom d'activité vous avez interagi avec",
        Topic_subject_or_title: "Thème, sujet ou titre",
        Select_at_least_one_skill_related_to_this_activity: "Sélectionnez au moins une compétence relative à cette activité",
        Select_at_least_one_related_skill: "Sélectionnez au moins une compétence relative",
        if_applicable: "s'il y a lieu",
        Degree_type_and_field: "Type de diplôme et domaine d'études",
        Field_of_study: "Domaine d'études",
        Name_of_the_school: "Nom de l'établissement",
        Location_of_the_school: "Lieu de l'établissement",
        You_havent_added_any_skills_yet: "Vous n'avez ajouté aucune compétence pour le moment.",
        Add_skills: "Ajouter des compétences",
        on_sur: "sur",
        in_sur: "en",
        for_pour: "pour",
        of_de: "de",
        about_sur: "sur",
        Additional_information: "Informations supplémentaires",
        Source: "Source",
        When_mark: "Quand?",
        Add_date: "Ajouter la date",
        Add_url: "Ajouter l'URL",
        Add_mentions_gpa: "Ajoutez résultats...",
        Enter_additional_information_here: "Entrer les informations supplémentaires ici",
        Examples_degree_context: "Résultats, distinctions, reconnaissances académiques, cours, options, projets, etc.",
        Examples_was_hired_context: "Réalisations, responsibilités, tâches, etc.",
        Source_url: "Source: URL",
        Date: "Date",
        When_was_the_object_acted_on: "Quand avez-vous agi sur l'objet?",
        Remove_date: "Retirer la date",
        Broken_link_alert: "URL non fonctionnel. Nous vous suggérons de supprimer cette activité et de créer une nouvelle avec un URL valid.",
        Broken_link: "Ooh, désolé! Ce URL n'est pas fonctionnel.",
        Approve_report: "Approuver le rapport",
        Uniform: "Uniforme",
        No_uniform: "Pas d'uniforme",
        Public_or_private_school: "Ecole publique ou privée?",
        Wearing_uniform: "Elèves en uniforme?",
        Uniform_pictures: "Photo d'uniformes",
        Location_lieu: "Lieu (ville, région)",
        Expected_date: "Date prévue",
        Date_of_issuing: "Date d'obtention",
        Type_of_course: "Type de cours",
        Type_of_training: "Type de formation",
        Type_of_internship: "Type de stage",
        Field_area: "Nom/Titre/Sujet/Domaine",
        Name_title_of_the_work: "Nom/titre de l'oeuvre",
        Add_infos: "Ajouter les infos",
        Name_event: "Nom/Evénement...",
        Till_now: "Jusqu'à présent",
        Till_now_present: "Jusqu'à présent",
        Jobposition: "Poste",

        Enter_the: "Entrer le",
        Type_of_degree: "Type de diplôme",
        Type_of_certification: "Type de certification",
        Type_of_licence: "Type de license",
        Type_of_membership: "Type de adhésion",
        Type_of_award: "Type de prix",
        Type_of_honor: "Type de distinction",
        Employer_name: "Nom de l'employeur",
        Job_title_and_position: "Intitulé du poste/Responsabilité",
        Album_title: "Titre de l'album",
        Name_of_artist_group_orchestra: `Nom de l'artiste/du groupe/de l'orchestre`,
        Artist_name: "Nom de l'artiste",
        Venue_name: "Nom du lieu (ex: salle, stade...) de l'événement",

        Name_of_issuing_body: "Nom de l'établissement",
        Name_of_issuing_honors_body: "Nom de l'organisme qui décerne la distinction",
        Name_of_awarding_body: "Nom de l'entité qui décerne le prix",
        Name_of_certifying_body: "Nom de l'établissement/institution",
        Name_of_licensing_body: "Nom de l'établissement/institution",
        Name_of_membership_body: "Nom de l'établissement",
        Name_of_the_high_school: "Nom de l'école",
        Name_of_the_college: "Nom du l'éccole supérieure",
        Name_of_the_university: "Nom de l'université",

        added_a_soft_skill: "a ajouté une compétence humaine",
        added_a_soft_skill_to_his_x_page: "a ajouté une compétence humaine à sa X Page",
        added_a_hard_skill: "a ajouté une compétence technique",
        added_a_hard_skill_to_his_x_page: "a ajouté une compétence technique à sa X Page",
        View_the_x_page: "Voir la X Page",
        View_the_x_graph: "Voir eXperience Graph",
        Type_the_skill_here: "Taper la compétence ici",


        New_data_nlles: "Nlle entrée",
        Entry: "Entrée",
        Universities: "Universités",
        Universities_colleges: "Universités/Ecoles supérieures",
        High_schools: "Collèges/Lycées",
        Influencers: "Influenceurs",
        Points_of_interests: "Centres d'intérêts",
        Size_store: "Taille",
        Add_geolocation_coordinates: "Ajouter les coordonnées de géolocalisation?",
        Photos_up_to_height: "Photos (max 8)",
        Add_photo: "Ajouter une photo",
        Data_successfully_saved: "Donnée sauvegardées avec succès",
        Entries: "Entrées",
        Geolocation: "Géolocalisation",
        Photos: "Photos",
        Notoriety: "Notoriété",
        Plus_website_sn: "+ Site web, Réseaux sociaux",
        Sector: "Secteur",
        Individual: "Individu",
        Cooperative: "Coopérative",
        Speciality_occupation: "Spécialité/Métier",
        University: "Université",
        College: "Ecole supérieure",
        Employees: "Employés",
        Save_silently: "Sauvegarder en silence",
        Entries_from: "Entrées du",
        entries_to: "au",
        Distribution_of_entries: "Répartition des entrées",
        View_all_facets: "Voir toutes les facettes",
        Add_facets: 'Ajouter les facettes',
        No_facets_yet: "Aucune facette ajoutée pour le moment",
        Create_story_page: "Créer une story page",
        x_page_of: "X Page de",
        Add_story_page: "Ajouter une story page",
        All_facets: "Toutes les facettes",
        All_my_facets: "Toutes mes facettes",
        All_my_facets_included: "Toutes mes facettes incluses",
        From_all_facets_of_me: "De toutes mes facettes",

        Supermarket: "Supermarché",
        Bonjour_total: "Bonjour Total",

        Posted_by: "Postée par",
        Topics: "Thèmes",
        No_topics_added: "Aucun thème ajouté",
        New_topic: "Nouveau thème",
        All_messages: "Tous les messages",
        Add_to_remove_from_topic: "Ajouter aux/Retirer des thèmes",
        Activity_report: "Rapport d'activité",
        My_activity_reports: "Mes rapports d'activité",
        Your_last_activity_report_has_been_approved: "Votre dernier rapport d'activité a été approuvé",
        Approved: "Approuvé",
        Sent_on: "Envoyé le",
        To_be_reviewed: "A être évalué",
        Task: "Tâche",
        Tasks: "Tâches",
        Employee_name: "Nom de l'Employé(e)",
        Employee_identification_number: "Numéro d'Identification",
        Department: "Département",
        Week: "Semaine",
        Completed_tasks: "Tâches Réalisées",
        Ongoing_tasks: "Tâches en Cours",
        Tasks_for_next_week: "Tâches pour la Semaine Prochaine",
        Report_successfully_sent: "Rapport envoyé avec succès",
        No_tasks: "Aucune Tâche",
        New_task: "Nouvelle Tâche",
        Start_date: "Date de début",
        End_date: "Date de fin",
        Priority: "Priorité",
        Progress: "Avancement",
        Assignees: "Assignés",
        Start: "Début",
        End: "Fin",
        Not_started: "Pas débutée",
        In_progress: "En cours",
        In_review: "En revue",
        Completed: "Terminée",
        Cancelled: "Annulée",
        Quit: "Quitter",
        Close: "Fermer",
        a_new_task_has_been_assigned_to_you: "Une nouvelle tâche vous a été assignée",
        one_of_your_tasks_has_been_deleted: "Une de vos tâches a été supprimée",
        your_tasks_status_has_been_changed: "Le statut de votre tâche a été modifié",
        your_tasks_start_date_has_been_changed: "La date de début de votre tâche a été modifiée",
        your_tasks_end_date_has_been_changed: "La date de fin de votre tâche a été modifiée",
        your_tasks_priority_has_been_changed: "La priorité de votre tâche a été modifiée",
        your_tasks_assignees_has_been_changed: "Les assignés de votre tâche ont été modifiés",
        your_tasks_subject_has_been_changed: "Le sujet de votre tâche a été modifié",
        your_tasks_description_has_been_changed: "La description de votre tâche a été modifiée",
        your_tasks_progress_has_been_changed: "L'avancement de votre tâche a été modifié",

        Hello: "Salut",
        Venue: "Lieu/Salle",
        View_more: "Voir plus",
        Read_more: "Lire la suite",
        Till_now_present: "Jusqu'à présent",
        Upload_pdf: "Importer le PDF",
        Upload_audio: "Importer l'audio",
        Brand_picture: "Photo de la marque",
        Brand_url: "URL de la marque",
        Show_name: "Nom du programme",
        Show_url: "URL du programme",
        Show_program: "Emission/Show",
        Click_to_enlarge: "Cliquer pour agrandir",
        Tap_to_enlarge: "Appuyer pour agrandir",
        play: 'écoute',
        preview: 'aperçu',
        view: 'vue',
        Signin_to_comment_and_react: "Connectez-vous pour commenter et réagir",
        Contacts_and_profiles: "Contacts & profils",
        Contact_links: "Contacts & liens",
        Doc_in_full_view: "Doc en plein écran",

        youAreWatching: "Vous regardez",
        paused: 'Pause',
        tryAccessingOtherQuality: 'Essayez de changer la qualité de la vidéo',
        goBack: 'Retour',
        speeds: 'Vitesse',
        nextEpisode: 'Prochain Episode',
        playlist: 'Playlist',
        playError: 'Un problème est survenu lors de la lecture de cette vidéo',
        Audios: 'Audios',
        Audio: 'Audio',
        Subtitles: 'Sous-titres',
        deactivated: "Désactivé",
        fr: "Français",
        en: "Anglais",
        ew: "Ewe",
        tm: "Tem (Kotokoli)",
        ka: "Kabyé",

        Website: "Site Web",
        No_links_to_display: "Aucun liens à afficher",

        Type_a_message: "Taper un message",

        Reply: "Répondre",
        View_replies: "Voir les réponses",

        Yesterday: "Hier",
        You_vous: "Vous",
        Audio: "Audio",
        Voice_messsage: "Message vocal",
        Voice_message: "Message vocal",
        Document: "Document",
        Contact: "Contact",
        Location: "Localisation",
        You_changed_your_phone_number: "Vous avez changé votre numéro de téléphone",
        changed_their_phone_number: "a changé son numéro de téléphone",
        changed_their_phone_number_long_message: "a changé son numéro de téléphone. Appuyer pour envoyer un message ou ajouter le nouveau numéro dans la liste des contacts",
        Message_deleted: "Message effacé",
        This_message_was_deleted: "Ce message a été effacé",
        To_start_messaging_contacts_who_have_activated_dchat: "Pour commencer à envoyer des messages aux contacts qui ont activé dChat, appuyez",
        at_the_top_of_your_screen: "situé dans le coin supérieur droit.",
        New_chat: "Nouv. Conversation",
        Select_contact: "Sélectionner un contact",
        contacts_min: "contacts",
        contact_min: "contact",
        New_contact: "Nouveau contact",
        New_group: "Nouveau groupe",
        NewGroup: "Nouveau Groupe",
        Add_participants: "Ajouter des participants",
        Participants: "Participants",
        Group_subject: "Sujet du groupe",
        New_group_message: "Veuillez ajouter le sujet du groupe et une icône, éventuellement.",
        created_the_group: "a créé le groupe",
        created_this_group: "a créé ce groupe",
        You_created_this_group: "Vous avez créé ce groupe",
        You_cant_send_messsages_to_this_group: "Vous ne pouvez pas envoyer de messages à ce groupe car vous n'en êtes plus un participant.",
        changed_the_group_subject: "a changé le sujet du groupe",
        You_changed_the_group_subject: "Vous avez changé le sujet du groupe",
        changed_the_group_description: "a changé la description du groupe",
        You_changed_the_group_description: "Vous avez changé la description du groupe",
        deleted_the_group_description: "a supprimé la description du groupe",
        You_deleted_the_group_description: "Vous avez supprimé la description du groupe",
        Tap_here_to_see_the_description: "Appuyez ici pour voir la description",
        changed_this_groups_icon: "a changé l'icône de ce groupe",
        changed_the_groups_icon: "a changé l'icône du groupe",
        You_changed_this_groups_icon: "Vous avez changé l'icône de ce groupe",
        You_changed_the_group_icon: "Vous avez changé l'icône du groupe",
        added_you: "vous a ajouté",
        added: "a ajouté",
        You_added: "Vous avez ajouté",
        removed: "a retiré",
        removed_you: "vous a retiré",
        You_removed: "Vous avez retiré",
        Youre_no_longer_an_admin: "Vous n'êtes plus un administrateur",
        Youre_now_an_admin: "Vous êtes maintenant un administrateur",
        Group_info: "Infos du groupe",
        Group_description: "Description du groupe",
        Add_group_description: "Ajouter une description au groupe",
        Group_description_message: "La description du groupe est visible pour tous les participants de ce groupe.",
        Edit_group_admins: "Modifier les admins du groupe",
        Edit_admins: "Modifier les admins",
        Exit_group: "Quitter le groupe",
        Exit: "Quitter",
        Report_group: "Signaler le groupe",
        Edit_group_info: "Modifier les infos du groupe",
        Edit_group_info_message: "Choisissez qui peut changer le nom du groupe, l'icône et la description.",
        Send_messages: "Envoi de messages",
        Send_messages_message: "Choisissez qui peut envoyer des messages dans ce groupe.",
        All_participants: "Tous les participants",
        Only_admins: 'Admins uniquement',
        to_group: "au groupe",
        group_question: "?",
        from_the_group: "du groupe",
        Infos: "Infos",
        Send_a_message: "Envoyer un message",
        You_made: "Vous avez nommé",
        madepp: "a nommé",
        made_group_admin: "en tant qu'admin du groupe",
        Make_group_admin_verb: "Nommer",
        Make_group_admin: "Nommer admin du groupe",
        a_group_admin: "en tant qu'admin du groupe?",
        You_dismissed: "Vous avez démis",
        dismissed: "a démis",
        dismissed_as_admin: "de son rôle d'admin",
        Dismiss: "Démettre",
        Dismiss_as_admin: "Démettre l'admin",
        as_admin: "de son rôle d'admin?",
        Remove_from_group: "Retirer du Groupe",
        from_group: "du Groupe?",
        Group_created_by: "Groupe créé par",
        Created_on: "Créé le",
        Save_enregistrer: "Enregistrer",
        You_left: "Vous êtes parti(e)",
        participant_left: "est partie(e)",
        Only_seuls: "Seuls",
        les_admins: "les admins",
        can_send_messages: "peuvent envoyer des messages",
        You_changed_this_group_settings_info: "Vous avez changé les réglages de ce groupe de façon à ce que seuls les admins puissent modifier les informations du groupe",
        changed_this_group_settings_info: "a changé les réglages de ce groupe de façon à ce que seuls les admins puissent modifier les informations du groupe",
        You_changed_this_group_settings_info_all: "Vous avez changé les réglages de ce groupe de façon à ce que tous les participants puissent modifier les informations du groupe",
        changed_this_group_settings_info_all: "a changé les réglages de ce groupe de façon à ce que tous les participants puissent modifier les informations du groupe",
        You_changed_this_group_settings_sendmessages: "Vous avez changé les réglages de ce groupe de façon à ce que seuls les admins puissent envoyer des messages dans ce groupe",
        changed_this_group_settings_sendmessages: "a changé les réglages de ce groupe de façon à ce que seuls les admins puissent envoyer des messages dans ce groupe",
        You_changed_this_group_settings_sendmessages_all: "Vous avez changé les réglages de ce groupe de façon à ce que tous les participants puissent envoyer des messages dans ce groupe",
        changed_this_group_settings_sendmessages_all: "a changé les réglages de ce groupe de façon à ce que tous les participants puissent envoyer des messages dans ce groupe",
        Already_added_to_the_group: "Déjà ajouté(e) au groupe",
        Done_termine: "Terminé",
        singleselected: "sélectionné",
        pluralselected: "sélectionnés",
        You_cant_send_messages_to_this_group_no_participant: "Vous ne pouvez pas envoyer de messages à ce groupe car vous n'êtes plus un participant",
        Youre_no_longer_a_participant_in_this_group: "Vous ne participez plus à ce message",
        at_time: "à",
        You_have_been_removed: "Vous avez été retiré(e)",
        You_have_been_added: "Vous avez été ajouté(e",
        You_added_a_participant: "Vous avez ajouté un participant",
        You_removed_a_participant: "ous avez retiré un participant",

        Invite_verb: "Inviter",
        Yello_iam_using_dchat: "Salut! J'utilise dChat.",
        Search_results: "Résultats de la recherche",
        Invite_via_sms_boby: "Tu connais Debyoo? J'utilise dChat de Debyoo pour communiquer avec les gens qui me sont proches. Tu peux le télécharger ici https://debyoo.com/dl",


        System_status: "État du système",
        Available: "Disponible",
        Open_in_the_debyoo_app: "Télécharger ou Ouvrir dans l'app Debyoo",
        more_min: "plus",
        Dark_mode: "Mode sombre",
        Language: 'Langue',
        "English": "Anglais",
        "French": "Français",
        "Download_debyoo": "Télécharger Debyoo",
        "signin": "Se connecter",
        "signup": "S'enregistrer",
        "signout": "Se déconnecter",
        Modify: "Modifier",
        An_error_occurred_while_processing_your_request: "Une erreur est survenue lors du traitement de votre demande",
        account_settings: "Paramètres du compte",
        Blocked_profiles: "Profils Bloqués",
        Payment_methods: "Méthodes de paiement",
        About_me: "A propos de moi",
        "Full_name": "Nom complet",
        Screenname: "Nom d'utilisateur",
        Email: "Email",
        "Password": "Mot de passe",
        "Forgot_password": "Mot de passe oublié?",
        resetpassword: "Réinitialiser le mot de passe",
        reset: "Réinitialiser",
        A_code_has_been_sent: "Un code a été envoyé à votre adresse email. Veuillez saisir ce code ici.",
        A_code_has_been_sent_to_you: "Un code a été envoyé à votre numéro de téléphone. Veuillez saisir ce code ici.",
        You_entered_an_incorrect_code: "Vous avez saisi un code incorrect. Veuillez vérifier votre code et réessayez à nouveau",
        sn_taken: "Cet nom d'utilisateur est déjà pris.",
        sn_invalid: "Nom d'utilisateur trop court (min 2 caract.)",
        Current_password: "Mot de passe actuel",
        New_password: "Nouveau mot de passe",
        New_password_confirmation: "Confirmation du nouveau mot de passe",
        Current_password_is_invalid: "Le mot de passe actuel est incorrect",
        New_password_and_confirmation_password_do_not_match: "Le nouveau mot de passe et la confirmation sont différents",
        New_password_must_contain_at_least_characters: "Le mot de passe doit contenir au moins 6 caractères",
        Password_cant_be_changed: "Une erreur est survenue. Le mot de passe n'a pas pu être modifié.",
        sn_warning_one: "Votre nom d'utilisateur doit contenir au moins 2 caractères",
        sn_warning_two: "Votre nom d'utilisateur ne peut contenir plus de 15 caractères",
        sn_warning_three: "Les noms d'utilisateur se composent uniquement de caractères alphanumériques (lettres de A à Z, nombres de 0 à 9), de traits de soulignement (_) et de points (.)",

        Continue: "Continuer",
        Enter_code: "Saisir le code",
        Send_code_again: "Renvoyez-moi encore le code",
        Create_a_new_password: "Créez un nouveau mot de passe",
        Type_a_new_password: "Nouveau mot de passe",
        sign_in_error: "Email ou mot de passe incorrect",
        email_not_found: "Nous ne retrouvons pas cet email.",
        you_will_receive_an_email_with_instructions: "Vous allez recevoir un email contenant les instructions pour réinitialiser votre mot de passe",
        password_blank: "Le mot de passe est requis.",
        password_short: "Mot de passe trop court (min. 6 caract).",
        name_blank: "Le nom est requis.",
        email_blank: "L’email est requis.",
        email_taken: "Cet email est déjà pris.",
        email_not_found: "Nous ne retrouvons pas cet email.",
        email_invalid: "Vérifiez le format de l’email.",
        I_agree_to_debyoos: "J'accepte",
        terms_short: "les Conditions d'Utilisation",
        Privacy_policy: "la Politique de Vie Privée",
        and: "et",
        eula_short: "la Licence de l'Utilisateur Final",
        Eulagreement: "Contrat de Licence Utilisateur Final",
        of_debyoo_fr: "de Debyoo",
        You_must_agree_to_the_terms: "Vous devez accepter les Conditions d'utilisation et la Licence de l'utilisateur final avant de vous enregistrer.",
        Welcome_to_Debyoo: "Bienvenue sur Debyoo!",
        Change_profile_photo: "Changer la Photo de Profil",
        Do_it_later: "Je le ferai plus tard",
        Continue: "Continuer",
        Move_and_scale: "Déplacer et Modifer l'échelle",
        Save: "Sauvegarder",
        Cancel: "Annuler",
        "Home": "Accueil",
        "Our_mission": "Notre mission",
        Our_vision: "Notre vision",
        "Contact": "Contact",
        "Contact_us": "Nous Contacter",
        "Privacy": "Vie privée",
        "Terms": "Termes d'utilisation",
        "Email": "Email",
        "General_inquiries": "Renseignements généraux",
        "Press": "Presse",
        "Legal": "Aspect juridique",
        "Support": "Assistance",
        "Music_industry": "Industrie de la musique",
        "For_inquiries_related_to_song_pages": "Pour les renseignements concernant les Song Pages",
        "Fashion_industry": "Industrie de la mode",
        "Debyoo_introduces_songpages": "Debyoo introduit les Song Pages",
        "Rethinking_songpage": "(Ré)imaginer la création, la distribution et la promotion de la musique.",
        "Redefining_the_future_of_digital_music_marketing_and_distribution": "Nous redéfinissons le futur de la distribution et du marketing digital de la musique.",
        "No_ intermediaries_songpage": "Artiste ou Label indépendant? Créez et publiez vos Song Pages sur Debyoo sans passer par un intermédiaire.",
        "Simple_transparent_models": "Modèle de revenus simple et transparent pour les artistes, labels, auteur-compositeurs et éditeurs.",
        "congratulations_your_email_address_has_been_confirmed": "Félicitations, votre adresse email a été confirmée et votre compte est à présent activé",
        "New_story": "Nouvelle Story",
        "Africa": "Afrique",
        "Investor'": "Investisseur",
        Sorry_this_page_isnt_available: "Désolé, cette page n'est pas disponible.",
        Page_doesnt_exist_no_more: "La page que vous cherchez n'existe pas, a été transférée ou n'existe plus.",
        Sorry_this_post_isnt_available: "Désolé, cette publication n'est pas disponible.",
        Post_doesnt_exist_no_more: "La publication que vous cherchez n'existe pas ou n'existe plus.",

        Followers: "Abonnés",
        Following: "Abonnements",
        follow: "S’abonner",
        unfollow: "Se désabonner de",
        unfollow_verb: "Se désabonner",
        follow_tooltip: "S’abonner à ce profil",
        unfollow_tooltip: "Se désabonner ce profil",
        following_state: "Abonné",

        Posts_plugs: "Posts·Plugs",
        Posts_and_plugs: "Posts & Plugs",
        No_posts_plugs_yet: "Aucune publication, aucun plug actuellement.",
        No_plugs_yet: "Aucun plug actuellement.",

        When_people_plug: "Lorsque les personnes vont plugguer (amplifier) les publications de",
        content_those_plugs_will_live_here: ", leurs plugs apparaîtront ici.",
        content_those_plugs_will_live_here_facet: " relatives à cette facette, leurs plugs apparaîtront ici.",

        When_people_plug_me: "Lorsque les personnes vont plugguer (amplifier) vos publications, leurs plugs apparaîtront ici.",
        content_those_plugs_will_live_here_me: "",

        When_people_plug_facet_me: "Lorsque les personnes vont plugguer (amplifier) vos publications relatives à cette facette, leurs plugs apparaîtront ici.",

        Post_of_one: "Les publications de ",
        Post_of_two: " apparaîtront ici (càd les posts qu'il/elle va publier directement sur son profil et ceux qu'il/elle va publier dans ses story pages).",
        Post_of_three: "Lorsque que ",
        Post_of_four: " va plugguer (amplifier) ses publications ou celles des autres personnes, ses plugs apparaîtront également ici.",

        Post_of_facet_one: "Les publications de ",
        Post_of_facet_two: " relatives à cette facette apparaîtront ici (càd les posts qu'il/elle va publier directement sur son profil et ceux qu'il/elle va publier dans ses story pages).",
        Post_of_facet_three: "Lorsque que ",
        Post_of_facet_four: " va plugguer (amplifier) ses publications ou celles des autres personnes et relater ces plugs à cette facette, ces derniers apparaîtront également ici.",

        Post_of_me_one: "Vos publications apparaîtront ici (càd les posts  que vous allez publier directement sur votre profil et ceux qui vous allez publier dans vos story pages).",
        Post_of_me_two: "Losque vous allez plugguer (amplifier) vos publications ou celles des autres personnes, vos plugs apparaîtront également ici.",

        Post_of_me_facet_one: "Vos publications relatives à cette facette apparaîtront ici (càd les posts  que vous allez publier directement sur votre profil et ceux qui vous allez publier dans vos story pages).",
        Post_of_me_facet_two: "Losque vous allez plugguer (amplifier) vos publications ou celles des autres personnes et relater ces plugs à cette facette, ces derniers apparaîtront également ici.",

        Edit_facets: "Editer les facettes",
        Facets: "Facettes",
        No_facets: "Aucune facette",
        New_profile_facet: "Nouvelle facette de profil",
        New_facet: "Nouvelle facette",
        View_all_facets: "Voir toutes les facettes",
        No_facets_yet: "Aucune facette ajoutée pour le moment",
        x_page_of: "X Page de",
        From_all_facets_of_me: "From all facets of me",

        Unpublished: "Non publiée",
        Published: "Publiée",
        In_review: "En cours de révision",
        being_reviewed: "En train d’être revue...",
        pending_unpublish: "Dépublication en traitement...",

        When_people_plug_me: "Lorsque les personnes vont plugguer (amplifier) vos publications, leurs plugs apparaîtront ici.",
        content_those_plugs_will_live_here_me: "",
        Post_of_one: "Les publications de ",
        Post_of_two: " apparaîtront ici (càd les posts qu'il/elle va publier directement sur son profil et ceux qu'il/elle va publier dans ses story pages).",
        Post_of_three: "Lorsque que ",
        Post_of_four: " va plugguer (amplifier) ses publications ou celles des autres personnes, ses plugs apparaîtront également ici.",

        Post_of_facet_one: "Les publications de ",
        Post_of_facet_two: " relatives à cette facette apparaîtront ici (càd les posts qu'il/elle va publier directement sur son profil et ceux qu'il/elle va publier dans ses story pages).",
        Post_of_facet_three: "Lorsque que ",
        Post_of_facet_four: " va plugguer (amplifier) ses publications ou celles des autres personnes et relater ces plugs à cette facette, ces derniers apparaîtront également ici.",

        Post_of_me_one: "Vos publications apparaîtront ici (càd les posts  que vous allez publier directement sur votre profil et ceux qui vous allez publier dans vos story pages).",
        Post_of_me_two: "Losque vous allez plugguer (amplifier) vos publications ou celles des autres personnes, vos plugs apparaîtront également ici.",

        Post_of_me_facet_one: "Vos publications relatives à cette facette apparaîtront ici (càd les posts  que vous allez publier directement sur votre profil et ceux qui vous allez publier dans vos story pages).",
        Post_of_me_facet_two: "Losque vous allez plugguer (amplifier) vos publications ou celles des autres personnes et relater ces plugs à cette facette, ces derniers apparaîtront également ici.",

        Post_published: "Publiée",
        Published: "Publiée",
        Posts: "Publications",
        Visits: "Visites",

        reaction: "réaction",
        reactions: "réactions",

        This_story_page_has_no_posts_yet: "Cette Story Page n'a aucune publication pour le moment.",
        No_posts_to_show: "Aucune publication à afficher.",
        To_see_some_posts_here: "Pour voir des publications s'afficher ici, vous devez vous abonner auprès d'autres comptes (suivre d'autres comptes).",

        Enter_story_page: "Accéder à la Story Page",

        Download: "Télécharger",

        Live_video_on_debyoo: "Vidéo en direct sur Debyoo",
        Live_audio_on_debyoo: "Audio en direct sur Debyoo",
        Live_video: "Vidéo en direct",
        Live_audio: "Audio en direct",
        Start_live_verb: "Lancer",
        Being_live: "En direct",
        End_live_verb: "Terminer",
        Checking_connection: 'Vérification de la connexion',
        Youre_live_now: "Vous êtes en direct maintenant",
        Were_having_trouble_starting_your_live_video: "Nous avons des difficultés à démarrer votre vidéo en direct.",
        Were_having_trouble_starting_your_live_audio: "Nous avons des difficultés à démarrer votre audio en direct.",
        Live_video_ended: "La vidéo en direct est terminée",
        Live_audio_ended: "L'audio en direct est terminé",
        viewers: "spectateurs",
        viewer: "spectateur",
        listeners: "auditeurs",
        listener: "auditeur",
        Peak: "Pic",
        joined_live_video: "a rejoint la vidéo",
        joined_live_audio: "a rejoint le direct",
        Signin_to_watch_this_live_video: "Connectez-vous pour regarder cette vidéo en direct",
        Signin_to_comment: "Connectez-vous pour commenter",
        Download_debyoo_to_watch_this_live_video:  "Téléchargez l'application Debyoo  pour regarder cette vidéo en direct",
        Were_having_trouble_streaming_this_right_now: "Nous avons des difficultés à diffuser ceci en ce moment.",
        There_is_an_issue_with_the_connection: "Il y a un problème avec la connexion du diffuseur.",
        There_is_an_issue_with_your_connection: "Il y a un problème avec la connexion.",
        Lives: "En direct",
        Lives_and_spots: "Directs & Spots",

        Your_comment: "Votre commentaire...",
        Link_copied_to_clipboard: "Lien copié dans le presse-papiers",
        Copy_link: "Copier le Lien",

        Publish_a_post: "Poster une Publication",
        Create_a_story_page: "Créer une Story Page",
        Create_a_song_page: "Créer une Song Page",
        Song_title: "Titre de la chanson",
        Song_cover_photo: "Photo couverture de la chanson",
        Publish_directly_on_your_profile: "Postez directement sur votre profil",
        Pulish_directly_on_the_selected_profile: "Postez directement sur le profil sélectionné",
        Text: "Texte",
        Photo: 'Photo',
        Video: "Vidéo",
        Mute: "Couper",
        Unmute: "Ouvrir",
        Title: "Titre",
        Description: 'Description',
        Enter_text_here: "Entrez le texte ici",
        Enter_location_here: "Entrez le lieu ici",
        Post: "Publier",

        Like: "J'aime",
        Happy: 'Joyeux',
        Sad: "Triste",
        Angry: 'Colère',
        Inspired: "Inspiré",

        Your_shopping_basket_is_empty: "Votre panier est vide",
        Shopping_basket: "Panier d’achats",

        is_currently_not_available_in_your_region: "n'est actuellement pas disponible dans votre pays ou région.",
        is_currently_not_accessible_in_your_region: "n'est actuellement pas accessible dans votre pays ou région.",
        will_be_available_in_your_region_soon: "sera bientôt accessible dans votre région.",
        Dchat_web_will_be_available_soon: "dChat Web sera bientôt disponible.",
        Activate_dchat_in_the_debyoo_app_on_ios_or_android: "Pour accéder à dChat Web, vous devez activater d'abord dChat dans l'application Debyoo pour iOS ou Android.",

        no_bloqued_profiles: "Aucun Profil Bloqué",

        No_notifications: "Aucune notification",
        News_feed: "Fil d'actualités",

        CARD_NUMBER: "NUMÉRO DE CARTE",
        EXP_DATE: "DATE D'EXP.",


        Wallet: "Portefeuille",
        Your_debyoo_wallet_is_empty: "Votre Portefeuille Debyoo est vide",
        Delete_card_and_leave_yij_community: "Supprimer la carte et quitter la communauté Youth Initiative Jeunesse",
        Delete_card: "Supprimer la carte",
        Change_category: "Changer la catégorie",
        All_of_communitys_benefits: "Tous les avantages et services de la communauté ne sont disponibles que pour les membres de Youth Initiative Jeunesse.",
        If_you_delete_the_card_and_leave: "Si vous supprimez votre carte et quittez la communauté, vous perdrez tous les avantages.",
        We_delay_card_deletion_a_few_days_after: "Nous ne procédons à la suppression que quelques jours après la requête. Vous pouvez annuler la demande de suppression pendant cette période.",
        It_may_take_up_to_30_days_to_delete: "La suppression de la carte de membre de Youth Initiative Jeunesse peut prendre jusqu’à 15 jours. Votre carte est désactivée pendant cette période.",
        If_you_delete_your_card:  "Si vous supprimez votre carte",
        Card_deletion_in_progress: "Suppression de la carte en cours...",
        Cancel_the_detetion: "Annuler la suppression",
        Your_card_deletion_process_is_not_finished_yet: "Le processus de suppression de votre carte n'est pas encore terminé.",
        If_you_wish_to_cancel_the_card_deletion: "Si vous souhaitez l'annuler, appuyez «Réactiver Maintenant»",
        Change_card_categ_warning: "La demande de changement de catégorie fait l'objet d'une procédure de validation par l'équipe Youth Initiative Jeunesse. Vous recevrez une notification lorsque le changement sera validé.",
        Current_category: "Catégorie actuelle",
        Change_to: "Passer à",
        Enter_your_password_then_tap_change_category: "Tapez votre mot de passe, et puis appuyez «Changer la catégorie»",
        You_will_be_notified_when_it_is_approved: "Vous recevrez une notification lorsqu'elle sera approuvée",
        Your_request_has_been_successfully_submitted: "Votre demande a été envoyée avec succès",
        yinitj_approved_message: "Votre demande d'adhésion à la Youth Initiative Jeunesse a été approuvée. Votre carte de membre numérique est à présent disponible.",
        yinitj_categ_changed_message: "Youth Initiative Jeunesse: Le changement de catégorie de votre carte de membre a été approuvé.",
        yinitj_deleted_message: "Votre carte de membre a été supprimée. Vous ne faites plus partie de la communauté de la Youth Initiative Jeunesse.",
        Dreamer: "Rêveur",
        Initiator: "Initiateur",
        Elder: "Sage",
        dreamer: "Rêveur",
        initiator: "Initiateur",
        elder: "Sage",
        As_a_dreamer: "En tant que Rêveur",
        As_an_initiator: "En qu'Initiateur",
        As_an_elder: "En tant que Sage",
        A_dreamer: "Un Rêveur",
        An_initiator: "Un Initiateur",
        An_elder: "Un Sage",
        Card_reactivated: "Carte réactivée",

        Quit: "Quitter",
        Search: "Rechercher",
        or:  "ou",
        press_the_esc: "App. sur Esc",

        Explore: "Explorer",

        Your_search: "Votre recherche",
        Recent_searches: "Recherches récentes",
        returned_no_results: "n’a donné aucun résultat.",
        See_all_results_for: "Voir tous les résultats pour",
        Search_results: "Résultats de la recherche",

        Upload_profile_photo: "Importer une photo",
        Profile_picture: "Photo de profil",

        New_story_page: "Nouvelle Story Page",
        Enter_source_url_here: "Entrez l'URL de la source ici",
        Enter_title_here: "Entrez le titre ici",
        Enter_url_here: "Entrez l'URL ici",
        required: "requis",
        optional: "facultatif",
        Story_title: "Titre de la Story Page",
        Select: "Sélectionner",
        Category: "Catégorie",
        Privacy: "Confidentialité",
        Public: "Publique",
        Visible_only_by_me: "Visible seulement par moi",

        Unpublish_confirmation: "Confirmation de dépublication",
        Publish_confirmation: "Confirmation de publication",
        Unpublish_short: "Dépublier",
        Publish_short: "Publier",
        Unpublish: "Dépublier",
        Publish: "Publier",
        Are_you_sure_you_want_to_unpublish_this_storypage: "Êtes-vous sûr de vouloir dépublier cette story page?",
        Are_you_sure_you_want_to_unpublish_this_songpage: "Êtes-vous sûr de vouloir dépublier cette song page?",
        Are_you_sure_you_want_to_unpublish_this_shoppage: "Êtes-vous sûr de vouloir dépublier cette shop page?",
        Are_you_sure_you_want_to_publish_this_storypage: "Êtes-vous sûr de vouloir publier cette story page?",
        Are_you_sure_you_want_to_publish_this_songpage: "Êtes-vous sûr de vouloir publier cette song page?",
        Are_you_sure_you_want_to_publish_this_shoppage: "Êtes-vous sûr de vouloir publier cette shop page?",
        Are_you_sure_you_want_to_delete_this_storypage: "Êtes-vous sûr de vouloir supprimer cette story page?",
        Are_you_sure_you_want_to_delete_this_songpage: "Êtes-vous sûr de vouloir supprimer cette song page?",
        Are_you_sure_you_want_to_delete_this_post: "Êtes-vous sûr de vouloir supprimer cette publication?",

        Delete: "Supprimer",

        Story_page_picture: "Vignette de la Story Page",
        Create: "Créer",
        Create_the_story_page: "Créer la Story Page",
        Edit_story_page: "Editer la Story Page",
        Edit: "Editer",
        Saving_changes: "En train de sauvegarder les modifications",
        New_post: "Nouvelle publication",
        Add_post_to_the_story_page: "Ajouter une publication à la Story Page",
        Sending_the_post: "En train d'envoyer la publication...",
        Edit_post: "Editer la publication",

        Preview_of_the_story_page: "Aperçu de la Story Page",
        Upload_story_page_thumbnail: "Ajouter la vignette de la Story Page",
        Change_story_page_thumbnail: "Changer la vignette de la Story Page",
        Upload_song_page_thumbnail: "Ajouter la vignette de la Song Page",
        Change_song_page_thumbnail: "Changer la vignette de la Song Page",
        Upload_shop_page_thumbnail: "Ajouter la vignette de la Shop Page",
        Change_shop_page_thumbnail: "Changer la vignette de la Shop Page",
        Upload_photos: "Importer les photos",
        Upload_photo: "Importer la photo",
        Upload_videos: "Importer les vidéos",
        Upload_video: "Importer la vidéo",
        Next_photo: "Photo suivante",
        Next_video: "Vidéo suivante",
        Processing_new_media: "En train de traiter les fichiers...",
        Trim: "Couper",
        Crop: "Rogner",


        Highly_recommended: 'Vivement recommandé',
        Creating_the_story_page: "En train de créer la Story Page",
        Creating_the_song_page: "En train de créer la Song Page",
        Creating_the_shop_page: "En train de créer la Shop Page",

        Select_a_facet: "Sélectionner une Facette",
        Select_facet: "Sélectionner une facette de profil",
        You_havent_added_any_facets_yet: "Vous n'avez ajouté aucune Facette à votre profil pour le moment.",
        Add_facets_to_your_profile: "Ajoutez des Facettes à votre profil",
        Select_an_artist: "Sélectionner un Artiste",
        You_havent_added_any_artists_yet: "Vous n'avez ajouté aucun Artist pour le moment. Vous pouvez ajouter les artistes à partir de Debyoo Play Manager",
        Clear: "Effacer",
        Relate_the_post_to_the_selected_facet: "Relater la publication à la facette sélectionnée",

        Share_to_your_profile: "Partagez sur votre profil",
        Share_to_ones_profile: "Partager sur le profil",
        Share_also_to_your_profile: "Partagez également sur votre profil",
        Share_to_artist_profile: "Partagez sur le profile de l'artiste",

        Loading_the_post_in_its_context: "En train de charger la publication dans son contexte...",
        View_the_plugged_story: "Voir la story page qui a été amplifiée",
        View_the_plugged_post: "Voir la publication qui a été amplifiée",
        View_the_post_in_its_context: "Voir la publication dans son contexte",
        Loading_the_plugged_post_in_its_context: "En train de charger la publication qui a été amplifiée, dans son contexte...",

        View_all_plugs: "Voir tous les Plugs",
        Plug_story_page_amplify_story_page: "Plug Story Page = Amplifier la Story Page",

        Visits: "Visites",
        reaction: "réaction",
        reactions: "réactions",
        Reactions: "Réactions",
        No_reactions: "Aucune réactions",
        Replying_to: "Répondre à",

        comment_plural: 'commentaires',
        comment_single: 'commentaire',
        View_all_cmts: "Voir tous les",

        following_no_one: "Aucun abonnement",
        no_followers: "Aucun abonné",


        Sort: "Trier",
        Sort_story_pages: "Trier les Story Pages",
        Sort_posts: "Trier les Publications",
        Sorting: "Triage",
        Oldest_to_newest: "Du plus ancien au plus récent",
        Newest_to_oldest: "Du plus récent au plus ancien",
        Date_of_creation: "Date de création",
        Date_of_occurrence: "Date de l'événement",
        Date_of_occurrence_short: "Date de l'évt.",
        Location_post: "Lieu de l'événement",
        Location_post_short: "Lieu de l'évt.",
        View_all_story_pages: "Voir toutes les Story Pages",
        No_story_pages: "Aucune Story Page",
        Created: "Créée",
        Add_to_songpage: "Ajouter à la Song Page",
        Add_to_storypage: "Ajouter à la Story Page",
        Time_hour: "Heure",
        Era: "Ère",
        Period: "Période",
        Subperiod: "Sous-période",
        BC_before_christ: "BC (avant Christ)",
        AD_anno_domini: "AD (anno Domini)",
        Standard: "Standard",
        Year: "Année",
        Month: "Mois",
        Day: "Jour",
        Time: "Heure",
        Decade: "Décennie",
        Century: "Siècle",
        Millennium: "Millénaire",
        Early: "Début du",
        Middle: "Milieu du",
        Late: "Fin du",


        Internationalization: "Internationalisation",

        // STORY PAGE Categories
        art: "Art",
        automotive: "Automobile",
        beauty: "Beauté",
        biography: "Biographie",
        birth: "Naissance",
        book: "Livre",
        brand: "Marque",
        business: "Business",
        cause: "Cause",
        cinema: "Cinéma",
        civicrights: "Droits civiques",
        culturecivilizationpeoples: "Peuples, Civilisations & Cultures",
        design: "Design",
        diary: "Journal personnel",
        economy: "Economie",
        education: "Education",
        entertainment: "Divertisssement",
        event: "Evénement",
        family: "Famille",
        fashion: "Mode",
        fooddrink: "Aliments et Boisson",
        fundraising: "Levée de fonds",
        funny: "Drôlerie",
        game: "Jeux",
        gossip: "Gossip",
        globalwarning: "Rechauffement & Changement climatique",
        health: "Santé",
        history: "Histoire",
        job: "Emploi",
        legallaw: "Droit & Loi",
        lifestyle: "Style de vie",
        movie: "Film",
        music: "Musique",
        news: "Actualités",
        photography: "Photographie",
        politics: "Politique",
        portfolio: "Portefeuille",
        product: "Produit",
        relationship: "Relations",
        religion: "Religion",
        resume: "Curriculum Vitae",
        service: "Service",
        science: "Science",
        shopping: "Shopping",
        sport: "Sport",
        stripcartoon: "Bande dessinée",
        technology: "Technologie",
        television: "Télévision",
        travel: "Voyage",
        wedding: "Mariage",

        // Facettes
        History_new: "Histoire",
        Governance: "Gouvernance",
        Development_programme: "Programmes pour le développement",
        Cause_new: "Cause",
        Community_new: "Communauté",
        Religion_faith: "Religion & Foi",
        Politics_new: "Politique",
        Event_planning: "Evénementiel",
        Travel_tourism_hospitality: "Voyage / Tourisme / Hôtellerie",
        Book_industry: "Industrie du livre",
        Fashion_and_beauty: "Mode & beauté",
        Film_industry: "Cinéma",
        Education_new: "Education",
        Visual_arts: "Arts visuels",
        Sportandfitness: "Sport & Fitness",
        News_and_Media: "Médias et Presse",
        Finance: "Finance",
        Telecommunications: "Télécommunications",
        Food_and_beverage: "Agroalimentaire & Boissons",
        Automotive: "Automobile",
        Science_and_technology: "Science et technologie",
        Startups_and_innovation: "Startups et innovation",
        Lawandlegal: "Droit & Législation",
        Luxuryindustry: "Industrie du luxe",
        Humor_comedy: "Humour & Rire",
        Architecture_and_interior_design: "Architecture & design d'intérieur",
        Agriculture: "Agriculture",
        Construction_and_engineering: "Bâtiment et travaux publics",
  	    Industry_energy_and_mines: "Industrie, énergie et mines",
        Services: "Services",

        // Soft skills
  	 Communication: "Communication",
  	 Effective_communication: "Communication efficace",
  	 Active_listening: "Ecoute active",
  	 Confidence: "Confiance",
  	 Conflict_resolution: "Gestion des conflits",
  	 Negotiation: "Négociation",
  	 Public_speaking: "Prise de parole en public",
  	 Writing: "Ecriture",
  	 Nonverbal_communication: "Communication non verbale",
  	 Empathy: "Empathie",
   Persuasion: "Persuasion",
  	 Emotional_intelligence: "Intelligence émotionnelle",
   Storytelling: "Storytelling",
   Oral_presentation: "Présentation orale",
   Clear_presentation_of_ideas: "Présentation claire des idées",
   Knowledge_transmission: "Transmission de savoirs",
   Reading_body_language: "Lecture du langage corporel",
   Visual_communication: "Communication visuelle",
   Telephone_communication: "Communication téléphonique",

  	 Problem_solving: "Résolution de problèmes",
  	 Research: "Recherche",
  	 Leadership: "Leadership",
   Initiative_taking: "Prise d'initiatives",
   Sense_of_responsibility: "Sens des responsabilités",
  	 Risk_management: "Gestion des risques",
  	 Teamwork: "Travail d'équipe",
   Networking: "Réseautage",
  	 Critical_thinking: "Esprit critique",
  	 Analysis: "Analyse",
  	 Decision_making: "Prise de décision",
  	 Resourcefulness: "Ingéniosité",
   Proactivity: "Proactivité",
  	 Troubleshooting: "Dépannage",

  	 Creativity: "Créativité",
  	 Curiosity: "Curiosité",
  	 Learning_from_others: "Apprendre des autres",
  	 Open_mindedness: "Ouverture d'esprit",
  	 Taking_calculated_risks: "Prise des risques calculés",
  	 Innovation: "Innovation",
  	 Experimentation: "Expérimentation",

  	 Adaptability: "Adaptabilité",
  	 Consistency: "Consistance",
  	 Optimism: "Optimisme",
  	 Flexibility: "Flexibilité",
  	 Enthusiasm: "Enthousiasme",
  	 Cooperation: "Coopération",
  	 Patience: "Patience",
  	 Growth_mindset: "Etat d'esprit axé sur la croissance",

  	 Work_ethic: "Ethique de travail",
  	 Attention_to_detail: "Attention au détail",
  	 Integrity: "Integrité",
  	 Persistence: "Persistance",
  	 Time_management: "Gestion du temps",
  	 Organization: "Organisation",
  	 Dependability: "Fiabilité",
  	 Motivation: "Motivation",
  	 Perseverance: "Persévérance",
  	 Results_oriented: "Orienté résultats",
      Priority_management: "Gestion des priorités",

  		// Hard Skills
  		Search_engine_optimization: "Optimisation pour les Moteurs de Recherche (SEO)",
  		Search_engine_marketing: "Marketing sur les Moteurs de Recherche (SEM)",
  		Search_engine_advertising: "Publicité sur les Moteurs de Recherche (SEA)",
  		Social_media_optimization: "Optimisation des Médias Sociaux (SMO)",
  		Craft_design: "Création artisanale",
  		Machine_learning: "Machine Learning",
  		Databases: "Bases de données",
  		Data_visualization: "Visualisation de donées",
  		Fashion_content_creator: "Créateur de Contenu de Mode",
  		Data_science: "Data Science",
  		Drawing_with_sewing_machine: "Dessin/Broderie à la Machine à Coudre",
  		Business_know_how: "Savoir-faire des entreprises",
  		Project_management: "Gestion de Projet",
  		Budgeting: "Budgétisation",
  		Scheduling: "Etablissement du calendrier",
  		Planning: "Planification",
  		Risk_management: "Gestion des Risques",
  		Contract_management: "Gestion des Contracts",
  		Programming_languages: "Langages de programmation",
  		Editing: "Edition/Montage",
  		Post_production: "Post-Production",
  		Screenwriting: "Ecriture de scénario",
  		Directing: "Direction",
  		Producing: "Production",
  		Fashion_show_production: "Production de Défilé de Mode",
  		Fashion_show_direction: "Direction de Défilé de Mode",
  		Business_model_creation: "Creation de Modèle Economique",
  		Business_model_implementation: "Implementation de Modèle Economique",
  		Business_model_development: "Développement de Modèle Economique",

  		// Activities
  		Curiosity: "Curiosité",
  		read_article: "a lu un article",
  		read_article_dyn: "a lu un article %%%%",
  		read_book: "a lu un livre",
  		followed_user: "a suivi une personne",
  		watched_video: "a regardé une vidéo",
  		watched_video_dyn: "a regardé une vidéo %%%%",
  		listened_podcast: "a écouté un podcast",

  		Engagement: "Engagement",
  		attended_conference: "a assisté à une conférence",
  		attended_forum: "a assisté à un forum",
  		attended_meeting: "a assisté à une réunion",
  		attended_seminar: "a assisté à un séminaire",
  		joined_group: "a rejoint un groupe",

      Music_preview: "Extrait",
  		Song: "Chanson",
  		Album: "Album",
  		Recordings: "Enregistrements studios",
  		Performance_experience: "Expérience de scène",
      Work_experience: "Expérience professionnelle",
      Awards_and_honors: "Prix et distinctions",
  		Internship_and_training: "Stages et formations",
  		Certifications: "Certifications",
  		Learning_and_training: "Learning & training",
  		Education_and_training: "Education and training",
  		earned_degree: "a obtenu un diplôme",
  		earned_degree_dyn: "a obtenu un diplôme %%%%",
  		obtained_certification: "a obtenu une certification",
  		obtained_certification_dyn: "a obtenu une certification %%%%",
  		obtained_licence: "a obtenu une licence",
  		obtained_licence_dyn: "a obtenu une licence %%%%",
  		is_attending_high_school: "fréquente une école sécondaire",
  		is_attending_high_school_dyn: "fréquente %%%%",
  		is_attending_college: "fréquente une école supérieure",
  		is_attending_college_dyn: "fréquente %%%%",
  		is_attending_university: "fréquente une université",
  		is_attending_university_dyn: "fréquente %%%%",
  		took_course: "a pris un cours",
  		took_course_dyn: "a pris un cours %%%%",
  		started_training: "a commencé une formation",
  		started_training_dyn: "a commencé une formation %%%%",
  		completed_training: "a terminé une formation",
  		completed_training_dyn: "a terminé une formation %%%%",
  		started_internship: "a commencé un stage",
  		started_internship_dyn: "a commencé un stage %%%%",
  		completed_internship: "a terminé un stage",
  		completed_internship_dyn: "a terminé un stage %%%%",
  		received_award: "a reçu un prix",
  		received_award_dyn: "a reçu un prix pour %%%%",
  		received_honor: "a reçu une distinction",
  		received_honor_dyn: "a reçu une distinction: %%%%",
  		was_hired: "a commencé un nouveau travail",
  		was_hired_dyn: "a commencé un nouveau travail chez %%%%",
  		appeared_on_song: "a participé à une chanson",
  		appeared_on_song_dyn: "a participé à la chanson «%%%%»",
  		appeared_on_album: "a participé à un album",
  		appeared_on_album_dyn: "a participé à l'album «%%%%»",
  		performed_at_event: "a presté à un événement",
  		performed_at_event_dyn: "a presté à l'événement de %%%%",
  		performed_a_job: "a effectué un travail ou une tâche",
  		performed_a_job_dyn: "a effectué un travail ou une tâche",
  		founded_an_entity: "a fondé une entité",
  		founded_an_entity_dyn: "a fondé: «%%%%»",
  		founded_an_entity_noun: "Fondateur(trice)",
  		created_an_object: "a créé un objet",
  		created_an_object_dyn: "a créé: «%%%%»",
  		created_an_object_noun: "Créateur(trice)",
  		coded_an_object: "a codé un objet",
  		coded_an_object_dyn: "a codé: «%%%%»",
  		coded_an_object_noun: "Codeur",
  		designed_an_object: "a designé un objet",
  		designed_an_object_dyn: "a designé: «%%%%»",
  		designed_an_object_noun: "Designer",
  		developed_an_object: "a développé un objet",
  		developed_an_object_dyn: "a développé: «%%%%»",
  		developed_an_object_noun: "Développeur",
  		invented_an_object: "a inventé un objet",
  		invented_an_object_dyn: "a inventé: «%%%%»",
  		invented_an_object_noun: "Inventeur",
  		programmed_an_object: "a programmé un objet",
  		programmed_an_object_dyn: "a programmé: «%%%%»",
  		programmed_an_object_noun: "Programmeur",

      Showcased_a_collection: 'Vous avez présenté une collection',
      Modeled_for: 'Vous avez posé pour',
      Walked_the_runway: 'Vous avez défilé pour',
      Choreographed_a_show: 'Vous avez chorégraphié un défilé',
      Managed_front_stage: 'Vous avez géré le devant de la scène',
      Managed_back_stage: 'Vous avez géré le backstage',
      Produced_a_fashion_show: 'Vous avez produit un défilé',
      Directed_a_fashion_show: 'Vous avez dirigé un défilé',
      Directed_a_casting: 'Vous avez dirigé un casting',
      Supervised_hairstyle: 'Vous avez supervisé la coiffure',
      Assisted_hairstylist: 'Vous avez assisté le coiffeur',
      Supervised_makeup: 'Vous avez supervisé le maquillage',
      Assisted_makupartist: 'Vous avez assisté le maquilleur',
      Worked_as_a_staff_member: 'Vous avez travaillé comme membre du staff',
      Hosted_a_show: 'Vous avez présenté un show',
      Hosted_a_event: 'Vous avez animé un événement',
      Moderated_a_panel: 'Vous avez modéré un panel',

      Showcased_a_collection_noun: 'Styliste/Créateur de mode',
      Modeled_for_noun: 'Modèle photo',
      Walked_the_runway_noun: 'Mannequin (défilé)',
      Choreographed_a_show_noun: 'Chorégraphe de défilé de mode',
      Managed_front_stage_noun: 'Manager de devant de la scène',
      Managed_back_stage_noun: 'Manager de backstage',
      Produced_a_fashion_show_noun: 'Producteur de défilé de mode',
      Directed_a_fashion_show_noun: 'Directeur de défilé de mode',
      Directed_a_casting_noun: 'Directeur de casting',
      Supervised_hairstyle_noun: 'Coiffeur(se)',
      Assisted_hairstylist_noun: 'Assistant(e) coiffeur',
      Supervised_makeup_noun: 'Maquilleur(se)',
      Assisted_makupartist_noun: 'Assistant(e) maquilleur',
      Worked_as_a_staff_member_noun: 'Membre du staff',
      Hosted_a_show_noun: 'Présentateur(trice)',
      Hosted_a_event_noun: 'Animateur(trice)',
      Moderated_a_panel_noun: 'Modérateur(trice)',

  		Application_of_knowledge: "Application des connaissances",
  		received_membership: "a reçu une adhésion",
  		received_membership_dyn: "a reçu une adhésion %%%%",

      // ALL_INSTRUMENTS_VOICES
      Voices: "Voix",
      Altovoice: "Voix alto",
      Baritonevoice: "Voix baryton",
      Bassvoice: "Voix basse",
      Castrato: "Castrato",
      Contraltovoice: "Voix contralto",
      Sopranovoice: "Voix soprano",
      Tenorvoice: "Voix ténor",
      Countertenorvoice: "Voix contreténor",
      Mezzosopranovoice: "Voix mezzo-soprano",

      // ALL_INSTRUMENTS_KEYBOARDS
      Keyboards: "Claviers",
      Accordion: "Accordéon",
      Celesta: "Celesta",
      Electricpiano: "Piano électrique",
      Hammondorgan: "Orgue Hammond",
      Harpsichord: "Harpsichord",
      Keyboard: "Clavier",
      Piano: "Piano",
      Pipeorgan: "Orgue à tuyaux",
      Reedorgan: "Orgue à anches",
      Synclavier: "Synclavier",
      Synthesizer: "Synthétiseur",

      // ALL_INSTRUMENTS_STRINGS
      Strings: "Cordes",
      Acousticguitar: "Guitare acoustique",
      Banjo: "Banjo",
      Bassguitar: "Basse",
      Cello: "Violoncelle",
      Contrabass: "Contrebasse",
      Electricguitar: "Guitare éléctrique",
      Guitar: "Guitare",
      Harp: "Harpe",
      Ukulele: "Ukulele",
      Viola: "Viola",
      Violin: "Violon",

      // ALL_INSTRUMENTS_PERCUSSIONS
      Percussions: "Percussions",
      Percussion: "Percussion",
      Beatboxing: "Beatboxing",
      Cabasa: "Cabasa",
      Drum: "Batterie",
      Bongos: "Bongos",
      Castanets: "Castagnettes",
      Cowbell: "Cowbell",
      Conga: "Conga",
      Djembe: "Djembe",
      Marimba: "Marimba",
      Steeldrum: "Steel Drum",
      Timpani: "Timbales",
      Tambourine: "Tambourin",
      Vibraphone: "Vibraphone",
      Xylophone: "Xylophone",

      // ALL_INSTRUMENTS_BRASS
      Brass: "Cuivres",
      Baritone: "Baritone",
      Cornet: "Cornet",
      Flugelhorn: "Bugle",
      Frenchhorn: "Cor d’harmonie",
      Euphonium: "Euphonium",
      Trombone: "Trombone",
      Trumpet: "Trompette",
      Tuba: "Tuba",

      // ALL_INSTRUMENTS_WOODWINDS
      Woodwinds: "Bois",
      Bagpipe: "Cornemuse",
      Bassoon: "Basson",
      Contrabassoon: "Contrebasson",
      Clarinet: "Clarinette",
      Bbclarinet: "Clarinette Bb",
      Bassclarinet: "Clarinette basse",
      Flute: "Flûte",
      Oboe: "Hautbois",
      Ocarina: "Ocarina",
      Organ: "Orgue",
      Panflute: "Flûte de Pan",
      Piccolo: "Piccolo",
      Harmonica: "Harmonica",
      Englishhorn: "Cor anglais",
      Recorder: "Recorder",
      Saxophone: "Saxophone",
      Sopranosaxophone: "Saxophone Soprano",
      Altosaxophone: "Saxophone Alto",
      Tenorsaxophone: "Saxophone Ténor",
      Baritonesaxophone: "Saxophone Baritone",

      // ALL_INSTRUMENTS_ELECTRONIC
      Computer_and_electronic: "Ordinateur et Electronique",
      Computer: "MAO (Musique assistée par ordinateur)",
      Drummachine: "Boîte à rythme",
      Sampler: "Echantillonneur",
      Djturntable: "Mixeur DJ",

      // COUNTRIES
      "AD" : "Andorre",
	"AE" : "Émirats Arabes Unis",
	"AF" : "Afghanistan",
	"AG" : "Antigua-Et-Barbuda",
	"AI" : "Anguilla",
	"AL" : "Albanie",
	"AM" : "Arménie",
	"AO" : "Angola",
	"AP" : "Région Asie/Pacifique",
	"AQ" : "Antarctique",
	"AR" : "Argentine",
	"AS" : "Samoa Américaines",
	"AT" : "Autriche",
	"AU" : "Australie",
	"AW" : "Aruba",
	"AX" : "Îles Åland",
	"AZ" : "Azerbaïdjan",
	"BA" : "Bosnie-Herzégovine",
	"BB" : "Barbad",
	"BD" : "Bangladesh",
	"BE" : "Belgique",
	"BF" : "Burkina Faso",
	"BG" : "Bulgarie",
	"BH" : "Bahreïn",
	"BI" : "Burundi",
	"BJ" : "Bénin",
	"BL" : "Saint-Barthélemy",
	"BM" : "Bermudes",
	"BN" : "Brunei Darussalam",
	"BO" : "État Plurinational De Bolivie",
	"BQ" : "Bonaire, Saint-Eustache Et Saba",
	"BR" : "Brésil",
	"BS" : "Bahamas",
	"BT" : "Bhoutan",
	"BV" : "Île Bouvet",
	"BW" : "Botswana",
	"BY" : "Biélorussie",
	"BZ" : "Belize",
	"CA" : "Canada",
	"CC" : "Îles Cocos",
	"CD" : "République Démocratique Du Congo",
	"CF" : "République Centrafricaine",
	"CG" : "Congo",
	"CH" : "Suisse",
	"CI" : "Côte D'Ivoire",
	"CK" : "Îles Cook",
	"CL" : "Chili",
	"CM" : "Cameroun",
	"CN" : "Chine",
	"CO" : "Colombie",
	"CR" : "Costa Rica",
	"CU" : "Cuba",
	"CV" : "Cap-Vert",
	"CW" : "Curaçao",
	"CX" : "Île Christmas",
	"CY" : "Chypre",
	"CZ" : "République Tchèque",
	"DE" : "Allemagne",
	"DJ" : "Djibouti",
	"DK" : "Denmark",
	"DM" : "Dominique",
	"DO" : "République Dominicaine",
	"DZ" : "Algérie",
	"EC" : "Équateur",
	"EE" : "Estonie",
	"EG" : "Égypte",
	"EH" : "Sahara Occidental",
	"ER" : "Érythrée",
	"ES" : "Espagne",
	"ET" : "Éthiopie",
	"EU" : "Europe",
	"FI" : "Finlande",
	"FJ" : "Fidji",
	"FK" : "Îles Malouines",
	"FM" : "États Fédérés De Micronésie",
	"FO" : "Îles Féroé",
	"FR" : "France",
	"GA" : "Gabon",
	"GB" : "Royaume-Uni",
	"GD" : "Grenade",
	"GE" : "Géorgie",
	"GF" : "Guyane",
	"GG" : "Guernesey",
	"GH" : "Ghana",
	"GI" : "Gibraltar",
	"GL" : "Groenland",
	"GM" : "Gambie",
	"GN" : "Guinée",
	"GP" : "Guadeloupe",
	"GQ" : "Guinée Équatoriale",
	"GR" : "Grèce",
	"GS" : "Géorgie Du Sud-Et-Les Îles Sandwich Du Sud",
	"GT" : "Guatemala",
	"GU" : "Guam",
	"GW" : "Guinée-Bissau",
	"GY" : "Guyana",
	"HK" : "Hong Kong",
	"HM" : "Îles Heard-Et-MacDonald",
	"HN" : "Honduras",
	"HR" : "Croatie",
	"HT" : "Haïti",
	"HU" : "Hongrie",
	"ID" : "Indonésie",
	"IE" : "Irlande",
	"IL" : "Israël",
	"IM" : "Île De Man",
	"IN" : "Inde",
	"IO" : "Territoire Britannique De L'océan Indien",
	"IQ" : "Irak",
	"IR" : "République Islamique D'Iran",
	"IS" : "Islande",
	"IT" : "Italie",
	"JE" : "Jersey",
	"JM" : "Jamaïque",
	"JO" : "Jordanie",
	"JP" : "Japon",
	"KE" : "Kenya",
	"KG" : "Kirghizistan",
	"KH" : "Cambodge",
	"KI" : "Kiribati",
	"KM" : "Comores",
	"KN" : "Saint-Christophe-et-Niévès",
	"KP" : "République Populaire Démocratique De Corée",
	"KR" : "République De Corée",
	"KW" : "Koweït",
	"KY" : "Îles Caïmans",
	"KZ" : "Kazakhstan",
	"LA" : "République Démocratique Populaire Lao",
	"LB" : "Liban",
	"LC" : "Sainte-Lucie",
	"LI" : "Liechtenstein",
	"LK" : "Sri Lanka",
	"LR" : "Liberia",
	"LS" : "Lesotho",
	"LT" : "Lituanie",
	"LU" : "Luxembourg",
	"LV" : "Lettonie",
	"LY" : "Libye",
	"MA" : "Maroc",
	"MC" : "Monaco",
	"MD" : "République De Moldavie",
	"ME" : "Monténégro",
	"MF" : "Saint-Martin (Partie Française)",
	"MG" : "Madagascar",
	"MH" : "Îles Marshall",
	"MK" : "Macédoine",
	"ML" : "Mali",
	"MM" : "Birmanie",
	"MN" : "Mongolie",
	"MO" : "Macao",
	"MP" : "Îles Mariannes Du Nord",
	"MQ" : "Martinique",
	"MR" : "Mauritanie",
	"MS" : "Montserrat",
	"MT" : "Malte",
	"MU" : "Maurice",
	"MV" : "Maldives",
	"MW" : "Malawi",
	"MX" : "Mexique",
	"MY" : "Malaisie",
	"MZ" : "Mozambique",
	"NA" : "Namibie",
	"NC" : "Nouvelle-Calédonie",
	"NE" : "Niger",
	"NF" : "Île Norfolk",
	"NG" : "Nigéria",
	"NI" : "Nicaragua",
	"NL" : "Pays-Bas",
	"NO" : "Norvège",
	"NP" : "Népal",
	"NR" : "Nauru",
	"NU" : "Niue",
	"NZ" : "Nouvelle-Zélande",
	"OM" : "Oman",
	"PA" : "Panama",
	"PE" : "Pérou",
	"PF" : "Polynésie Française",
	"PG" : "Papouasie-Nouvelle-Guinée",
	"PH" : "Philippines",
	"PK" : "Pakistan",
	"PL" : "Pologne",
	"PM" : "Saint-Pierre-Et-Miquelon",
	"PN" : "Pitcairn",
	"PR" : "Porto Rico",
	"PS" : "Territoires Palestiniens Occupés",
	"PT" : "Portugal",
	"PW" : "Palaos",
	"PY" : "Paraguay",
	"QA" : "Qatar",
	"RE" : "Réunion",
	"RO" : "Roumanie",
	"RS" : "Serbie",
	"RU" : "Fédération De Russie",
	"RW" : "Rwanda",
	"SA" : "Arabie Saoudite",
	"SB" : "Îles Salomon",
	"SC" : "Seychelles",
	"SD" : "Soudan",
	"SE" : "Suède",
	"SG" : "Singapour",
	"SH" : "Sainte-Hélène",
	"SI" : "Slovénie",
	"SJ" : "Svalbard Et Jan Mayen",
	"SK" : "Slovaquie",
	"SL" : "Sierra Leone",
	"SM" : "Saint-Marin",
	"SN" : "Sénégal",
	"SO" : "Somalie",
	"SR" : "Suriname",
	"SS" : "Soudan Du Sud",
	"ST" : "Sao Tomé-Et-Principe",
	"SV" : "République Du Salvador",
	"SX" : "Saint-Martin (Partie Néerlandaise)",
	"SY" : "République Arabe Syrienne",
	"SZ" : "Swaziland",
	"TC" : "Îles Turks-Et-Caïcos",
	"TD" : "Tchad",
	"TF" : "Terres Australes Françaises",
	"TG" : "Togo",
	"TH" : "Thaïlande",
	"TJ" : "Tadjikistan",
	"TK" : "Tokelau",
	"TL" : "Timor-Leste",
	"TM" : "Turkménistan",
	"TN" : "Tunisie",
	"TO" : "Tonga",
	"TR" : "Turquie",
	"TT" : "Trinité-Et-Tobago",
	"TV" : "Tuvalu",
	"TW" : "Taïwan",
	"TZ" : "République-Unie De Tanzanie",
	"UA" : "Ukraine",
	"UG" : "Ouganda",
	"UM" : "Îles Mineures Éloignées Des États-Unis",
	"US" : "États-Unis",
	"UY" : "Uruguay",
	"UZ" : "Ouzbékistan",
	"VA" : "Saint-Siège (État De La Cité Du Vatican)",
	"VC" : "Saint-Vincent-Et-Les Grenadines",
	"VE" : "Venezuela",
	"VG" : "Îles Vierges Britanniques",
	"VI" : "Îles Vierges Des États-Unis",
	"VN" : "Viet Nam",
	"VU" : "Vanuatu",
	"WF" : "Wallis Et Futuna",
	"WS" : "Samoa",
	"YE" : "Yémen",
	"YT" : "Mayotte",
	"ZA" : "Afrique Du Sud",
	"ZM" : "Zambie",
	"ZW" : "Zimbabwe",
      }
    },
  },
  lng: "en",
  fallbackLng: "en",

  interpolation: {
    escapeValue: false
  }
});

export default i18n;
